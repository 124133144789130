.section-category-slider {
    max-height: 200px;
    margin-bottom: 48px;

    .slick-arrow {
        cursor: pointer;
        width: 35px;
        height: 35px;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.167122);
        opacity: .7;
        transition: .2s;
        position: relative;

        &:hover {
            opacity: 1;

            &::after {
                border-color: transparent transparent transparent #FB8300;
            }
        }
    }

    .slick-arrow__next::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 8px;
        border-color: transparent transparent transparent #737779;
        position: absolute;
        left: 0;
        right: -2px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: .2s;
    }

    .slick-arrow__prev {
        transform: rotate(180deg);

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 0 7px 8px;
            border-color: transparent transparent transparent #737779;
            position: absolute;
            left: 0;
            right: -2px;
            top: 0;
            bottom: 0;
            margin: auto;
            -webkit-transition: .2s;
            transition: .2s;
        }
    }

}


.category-slider {
    position: relative;

    &__nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 10px 25px;
        bottom: 35px;
        left: 0;
        height: 0px;
    }

    &__arrows {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80px;
    }

    .slick {
        &-next {
            right: 20px;
        }

        &-prev {
            right: 60px;
            z-index: 2;
        }
    }

    &__img-wrapper {
        position: relative;
        padding-bottom: 15%;
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;

    }

}

@media (max-width: 1199px) {
    .section-category-slider {
        margin-bottom: 28px;
    }

}

@media (max-width: 991px) {
    .category-slider__nav {
        display: none;
    }
}