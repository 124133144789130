.person-block{
	display: flex;
	flex-direction: column;
	/*height: calc(100% - 10px);
	min-height: 410px;*/
	padding: 30px 35px;
	margin-bottom: 10px;
	background-color: #EEF1F4;
	&-top{
		display: flex;
		margin-bottom: 32px;
	}
	&-desc{
		font-size: 16px;
		line-height: 24px;
		color: #333;
	}
	&-name{
		margin-bottom: 3px;
		font-size: 22px;
		line-height: 26px;
		color: #333;
		font-weight: 700;
	}
	&-job{
		font-size: 14px;
		line-height: 18px;
		color: #7D8185;
	}
	&-top-right{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 0 4px;
	}
	&-pic{
		width: 120px;
		height: 140px;
		flex: 0 0 auto;
		margin-right: 20px;
		object-fit: cover;
		object-position: center;
	}
	&-no-photo{
		padding: 35px 34px;
		border: 1px solid #C6CED6;
		fill: #CBD2DA;
	}
	&-contact{
		display: block;
		line-height: 1;
		$this: &;
		&-icon{
			margin-right: 11px;
			width: 16px;
			height: 16px;
			fill: $color-orange;
		}
		&-value{
			vertical-align: middle;
			font-size: 16px;
			line-height: 1;
			color: #333;
			transition: .4s;
			&.mail{
				text-decoration: underline;
			}
		}
		&:not(:last-child){
			margin-bottom: 16px;
		}
		&:hover{
			#{$this}-value{
				color: $color-orange;
			}
		}
	}
}
@media (max-width: 1199px){
	.person-block{
		min-height: 330px;
		padding: 25px 18px;
		&-pic{
			width: 80px;
			height: 110px;
			margin-right: 15px;
		}
		&-no-photo{
			padding: 22px;
		}
		&-name{
			font-size: 18px;
			line-height: 1;
		}
		&-contacts{
		}
		&-contact{
			&:not(:last-child){
				margin-bottom: 6px;
			}
			&-icon{
				width: 14px;
				height: 14px;
				margin-right: 5px;
			}
			&-value{
				font-size: 15px;
				line-height: 1;
			}
		}
		&-top{
			margin-bottom: 20px;
		}
		&-desc{
			font-size: 15px;
			line-height: 20px;
		}
	}
}
@media(max-width: 767px){
	.person-block{
		height: auto;
		min-height: unset;
	}
}