.post-card{
    display: block;
    $this: &;
    &__thumb{
        width: 100%;
        height: 256px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__info{
        height: 155px;
        padding: 14px 10px 20px;
        border-bottom: 2px solid #D8D8D8;
        transition: .2s;
        span{
            font-family: 'Gotham Pro';
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FB8300;
            
        }
        p{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #333333;
            padding: 10px 0 0;
        }
    }
    &:hover{
        #{$this}__info{
            border-color: #FB8300
        }
    }
}

.post-block{
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    &__thumb{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 30px 45px;
        height: 100%;
        width: 75%;
        position: relative;
        z-index: 1;
        span{
            font-family: 'Gotham Pro';
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        p{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
        }
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        height: 2px;
        width: 100%;
        transition: .2s;
        background-color: #FB8300;
    }
    &:hover{
        &:after{
            bottom: 0;
        }
    }
}
.post-block__thumb::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    HEIGHT: 100%;
    BACKGROUND: rgba(0, 0, 0, 0.45);
}
@media (max-width: 1199px){
    .post-card{
        &__thumb{
            height: 213px;
        }
        &__info{
            height: 125px;
            p{
                padding: 3px 0 0;
            }
        }
    }
}
@media (max-width: 991px){
    .post-block{
        margin-bottom: 10px;
        &__thumb{
            position: static;
            height: 213px;
        }
        &__info{
            height: 125px;
            width: 100%;
            padding: 14px 10px 20px;
            border-bottom: 2px solid #D8D8D8;
            span{
                color: $color-orange;
            }
            p{
                padding: 3px 0 0;
                font-size: 18px;
                line-height: 24px;
                color: #333;
            }
        }
    }
}
@media (max-width: 767px){
    .post-card{
        margin-bottom: 10px;
    }
}