// Цвета
$color-alt: #122A49;
$color-text: #333;
$color-border: #e5e5e5;
$color-placeholder: #757575;
$color-orange: #FB8300;
$color-general: #FB8300;
$radius: 0;
$time: .4s;
$whiteIconZoom: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzAgMzAiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yOS42IDI3LjhsLTYuNi02LjVjMi0yLjMgMy4yLTUuMiAzLjItOC40IDAtNy4xLTUuOS0xMi45LTEzLjEtMTIuOS03LjIgMC0xMy4xIDUuOC0xMy4xIDEyLjlzNS45IDEyLjkgMTMuMSAxMi45YzMgMCA1LjgtMSA4LTIuN2w2LjcgNi42Yy4zLjMuNS40LjkuNHMuNy0uMS45LS40Yy41LS42LjUtMS40IDAtMS45em0tMjctMTQuOGMwLTUuNyA0LjctMTAuMyAxMC41LTEwLjNzMTAuNSA0LjYgMTAuNSAxMC4zLTQuNyAxMC4zLTEwLjUgMTAuMy0xMC41LTQuNi0xMC41LTEwLjN6TTE4LjMgMTEuNWgtMy45di0zLjhjMC0uOC0uNS0xLjMtMS4zLTEuM3MtMS4zLjUtMS4zIDEuM3YzLjdoLTMuOGMtLjggMC0xLjMuNS0xLjMgMS4zcy41IDEuMyAxLjMgMS4zaDMuOHYzLjljMCAuOC41IDEuMyAxLjMgMS4zczEuMy0uNiAxLjMtMS4zdi0zLjloMy45Yy43IDAgMS4zLS41IDEuMy0xLjNzLS41LTEuMi0xLjMtMS4yeiIvPjwvc3ZnPg==);

@import "mixins";


@import "components/base";

@import "components/header";
@import "components/index";
@import "components/footer";

@import "components/category";
@import "components/product";
@import "components/filters";

@import "components/posts";

@import "components/cart";

@import "components/notify";
@import "components/services-page";
@import "components/page";
@import "components/team";
@import "components/clients";
@import "components/404";
@import "components/category_slider";
@import "components/calc";