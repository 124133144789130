@import "../_mixin_test";

// FONTS
@font-face {
	font-family: 'Gotham Pro';
	src: url('../fonts/GothamPro/GothamPro-Black.eot');
	src: local('Gotham Pro Black'), local('GothamPro-Black'),
		url('../fonts/GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamPro-Black.woff') format('woff'),
		url('../fonts/GothamPro/GothamPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('../fonts/GothamPro/GothamPro-Bold.eot');
	src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
		url('../fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff'),
		url('../fonts/GothamPro/GothamPro-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('../fonts/GothamPro/GothamPro-Medium.eot');
	src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
		url('../fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff'),
		url('../fonts/GothamPro/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Narrow';
	src: url('../fonts/GothamPro/GothamProNarrow-Bold.eot');
	src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
		url('../fonts/GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamProNarrow-Bold.woff') format('woff'),
		url('../fonts/GothamPro/GothamProNarrow-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Narrow';
	src: url('../fonts/GothamPro/GothamProNarrow-Medium.eot');
	src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
		url('../fonts/GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamProNarrow-Medium.woff') format('woff'),
		url('../fonts/GothamPro/GothamProNarrow-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('../fonts/GothamPro/GothamPro.eot');
	src: local('Gotham Pro'), local('GothamPro'),
		url('../fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro/GothamPro.woff') format('woff'),
		url('../fonts/GothamPro/GothamPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'WeblySleek UI';
	src:
		local('WeblySleek UI Semilight Italic'), local('WeblySleekUISemilight-Italic'),
		url('../fonts/weblysleek/weblysleekuisemilightitalic.woff2') format('woff2'),
		url('../fonts/weblysleek/weblysleekuisemilightitalic.woff') format('woff'),
		url('../fonts/weblysleek/weblysleekuisemilightitalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'WeblySleek UI';
	src:
		local('WeblySleek UI Semibold'), local('WeblySleekUISemibold'),
		url('../fonts/weblysleek/weblysleekuisemibold.woff2') format('woff2'),
		url('../fonts/weblysleek/weblysleekuisemibold.woff') format('woff'),
		url('../fonts/weblysleek/weblysleekuisemibold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	min-height: 100%;
	height: 100%;
}

body {
	min-width: 320px;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Gotham Pro', sans-serif;

	&.opened {
		overflow: hidden;
	}
}

::-webkit-input-placeholder {
	color: $color-placeholder;
}

::-moz-placeholder {
	color: $color-placeholder;
}

:-moz-placeholder {
	color: $color-placeholder;
}

:-ms-input-placeholder {
	color: $color-placeholder;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

ol,
ul {
	padding: 0;
	margin: 0;
}

li {
	list-style-type: none;
}

.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4,
p,
label {
	line-height: normal;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

a:focus,
button:focus,
input:focus,
textarea:focus {
	outline: 0;
	text-decoration: none;
}

.container {
	padding: 0 5px;
}

.row {
	margin: 0 -5px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	padding: 0 5px;
}

.base {

	&-wrapper,
	&-container {
		width: 100%;
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		overflow: hidden;
	}

	&-container {
		flex: 1 auto;
		overflow: initial;
	}

	&-page {
		padding-bottom: 90px;
	}
}

@function calcFluidSize($f-min, $f-max, $v-min, $v-max) {
	$k: (
		$f-max - $f-min)/($v-max - $v-min
	);
$b: $f-min - $k * $v-min;
$b: $b * 1px;
@return calc(#{$k} * 100vw + #{$b});
}

.theme {
	&-flex-center {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&-title {
		&__text {
			position: relative;

			&-title {
				font-family: 'Gotham Pro Narrow';
				font-weight: 700;
				font-size: calcFluidSize(48, 126, 320, 1920);
				line-height: calcFluidSize(48, 118, 320, 1920);
				letter-spacing: -1px;
				text-transform: uppercase;
				color: #EEF1F4;
			}

			&-subtitle {
				display: inline-block;
				position: absolute;
				font-family: 'WeblySleek UI';
				font-style: italic;
				font-weight: 300;
				font-size: calcFluidSize(24, 66, 320, 1920);
				line-height: calcFluidSize(24, 88, 320, 1920);
				color: #333333;
				bottom: calcFluidSize(-3, -10, 320, 1920);
				left: 25%;
				white-space: nowrap;
			}
		}

		&__link {
			position: absolute;
			right: 5px;
			bottom: 28px;

			a {
				display: block;
				position: relative;

				span {
					font-family: 'Gotham Pro Narrow';
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
					text-align: center;
					letter-spacing: 0.218571px;
					text-transform: uppercase;
					color: #737779;
					position: relative;
					z-index: 1;
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					background-color: #E5E5E5;
					border-radius: 5px;
					width: calc(100% + 6px);
					height: 10px;
					left: -3px;
					bottom: 0px;
					transition: .2s;
				}

				&:hover {
					&:after {
						background-color: $color-orange;
					}
				}
			}

			&-mobile {
				display: none;
			}

			&-static {
				position: static;
			}
		}

		&-simple {
			padding-bottom: 40px;

			p {
				font-family: 'Gotham Pro Narrow';
				font-weight: 700;
				font-size: 36px;
				line-height: 44px;
				color: #333333;
			}

			.theme-title__link {
				bottom: 40px;
			}
		}
	}

	&-counter {
		display: flex;
		flex-direction: row;
		background: #FFFFFF;
		border: 1px solid #E8F1F6;
		border-radius: 3px;

		&__minus,
		&__plus {
			width: 33%;
			position: relative;
			cursor: pointer;
			transition: .2s;

			&::before {
				content: '';
				display: block;
				position: absolute;
				height: 2px;
				width: 13px;
				background-color: #333333;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: calc(50% - 1px);
				transition: .2s;
			}

			&.blocked {
				pointer-events: none;
				opacity: 0.5;
			}

			&:hover {

				&::before,
				&::after {
					background-color: $color-alt;
				}
			}
		}

		&__plus {
			&::after {
				content: '';
				display: block;
				position: absolute;
				height: 2px;
				width: 13px;
				background-color: #333333;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: calc(50% - 1px);
				transform: rotate(90deg);
				transition: .2s;
			}
		}

		&__input {
			width: 33%;
			text-align: center;
			border: none;
			outline: none;
			font-family: Gotham Pro;
			font-weight: 700;
			font-size: 18px;
			line-height: 24px;
			color: #333333;
		}
	}

	&-form {
		$this: &;
		position: relative;
		display: inline-flex;

		&__label {
			cursor: text;
			position: relative;
			font-family: 'Gotham Pro';
			border: 1px solid #D0D0D0;
			border-radius: 2px;

			&.active {
				span {
					transform: scale(.7);
					top: 4px;
					color: $color-orange;
				}
			}

			span {
				transform-origin: left top;
			}
		}

		span {
			font-size: 18px;
			line-height: 28px;
			color: #737779;
			position: absolute;
			left: 20px;
			top: calc(50% - 15px);
			transition: .2s;

			&.invalid {
				color: #FB4700;
			}
		}

		input:not([type=submit]),
		textarea {
			background-color: #FFFFFF;
			font-size: 18px;
			line-height: 28px;
			color: #737779;
			width: 100%;
			height: 100%;
			border: none;
			resize: none;
			padding: 20px 20px 0;
		}

		input[type=submit] {
			cursor: pointer;
			font-family: 'Gotham Pro Narrow';
			font-weight: 700;
			font-size: 14px;
			line-height: 13px;
			letter-spacing: 0.77px;
			text-transform: uppercase;
			color: #FFFFFF;
			background-color: $color-orange;
			border-radius: 3px;
			border: none;
			transition: .2s;

			&:hover {
				background-color: #FB4700;
			}
		}

		&__files {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__file {
			position: relative;
			cursor: pointer;
			flex: 0 0 49%;
			background: #FFFFFF;
			border: 1px dashed #D0D0D0;
			border-radius: 2px;
			height: 70px;
			transition: .2s;

			input {
				display: none;
			}

			&-content {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 100%;
				width: 100%;
				padding: 15px;

				svg {
					width: 18px;
					height: 18px;
					margin-right: 10px;
					fill: $color-orange;
				}

				p {
					font-size: 14px;
					line-height: 18px;
					color: #737779;
				}
			}

			&-info {
				visibility: hidden;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 15px;
				background-color: #fff;
				opacity: 0;
				transition: opacity .2s;

				&-name {
					font-size: 14px;
					line-height: 1.2;
				}
			}

			&:hover {
				border-color: $color-orange;
			}

			&-delete {
				position: absolute;
				left: 15px !important;
				bottom: 15px;
				top: unset !important;
				font-size: 10px !important;
				line-height: 1 !important;
				color: $color-orange !important;

				border-bottom: 1px dashed $color-orange;
				cursor: pointer;
			}

			$this: &;

			&.active {
				border-style: solid;
				border-color: $color-orange;

				#{$this}-info {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&__personal {
			font-family: 'Gotham Pro';
			padding: 10px 0 0;
			font-size: 11px;
			line-height: 18px;
			color: #AEB4B6;

			a {
				color: #AEB4B6;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__loader,
		&__ok {
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: rgba(255, 255, 255, 0.8);
		}

		&__ok {
			p {
				font-family: 'Gotham Pro Narrow';
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				color: #333333;
			}
		}

		&.loading {
			.theme-form__loader {
				display: flex;
			}
		}

		&.ok {
			.theme-form__ok {
				display: flex;
			}
		}
	}

	&-required {
		position: relative;

		&:after {
			content: '*';
			font-size: 16px;
			line-height: 16px;
			color: $color-orange;
		}
	}

	&-personal {
		padding: 15px 0 0;
		font-size: 13px;
		line-height: 20px;
		text-align: center;
		color: #8C8C8C;

		a {
			color: #8C8C8C;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-button {
		cursor: pointer;
		font-family: 'Gotham Pro Narrow';
		font-weight: 700;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.77px;
		text-transform: uppercase;
		color: #FFFFFF;
		background-color: $color-orange;
		border-radius: 3px;
		border: none;
		transition: .2s;

		&:hover {
			background-color: #FB4700;
			color: #fff;
		}
	}

	&-button-reverse {
		cursor: pointer;
		font-family: 'Gotham Pro Narrow';
		font-weight: 700;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.77px;
		text-transform: uppercase;
		color: $color-orange;
		background-color: #fff;
		border: 1px solid $color-orange;
		border-radius: 3px;
		transition: .2s;

		&:hover {
			background-color: $color-orange;
			color: #fff;
		}
	}

	&-icon-button {
		display: flex;
		flex-direction: row;
		background-color: #FFFFFF;
		border: 1px solid #DAE0E6;
		border-radius: 3px;
		padding: 10px 20px;
		width: 270px;
		transition: .2s;

		&__icon {
			margin-right: 20px;

			svg {
				width: 48px;
				height: 48px;
				fill: $color-orange;
			}
		}

		&__text {
			b {
				display: block;
				font-family: 'Gotham Pro Narrow';
				font-weight: 700;
				font-size: 18px;
				line-height: 26px;
				color: #333333;
			}

			span {
				font-family: 'Gotham Pro';
				font-size: 14px;
				line-height: 22px;
				color: #737779;
			}
		}

		&:hover {
			border-color: $color-orange;
		}
	}

	&-req-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #EEF1F4;
		border-radius: 3px;
		width: 200px;
		height: 40px;

		svg {
			width: 12px;
			height: 18px;
			flex: 0 0 12px;
			fill: #C3CBD3;
			margin-right: 12px;
			margin-top: -3px;
			transition: .4s;
		}

		span {
			font-family: 'Gotham Pro';
			font-size: 14px;
			line-height: 13px;
			color: #333333;
			transition: .4s;
		}

		&:hover {
			span {
				color: $color-orange;
			}

			svg {
				fill: $color-orange;
			}
		}
	}

	&-back-button {
		display: inline-flex;
		align-items: center;
		font-family: 'Gotham Pro';
		font-size: 16px;
		line-height: 15px;
		color: #333333 !important;
		background: #FFFFFF;
		border: 1px solid #D0D0D0;
		border-radius: 3px;
		padding: 15px 20px;
		transition: .2s;

		svg {
			fill: $color-orange;
			width: 8px;
			height: 13px;
			transform: rotate(180deg);
			margin-right: 14px;
		}

		&:hover {
			border-color: $color-orange;
		}
	}

	&-line-button {
		padding: 5px 0;
		display: inline-flex;
		position: relative;

		span {
			font-family: 'Gotham Pro Narrow';
			font-weight: 700;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.145714px;
			text-transform: uppercase;
			color: $color-orange;
			position: relative;
			z-index: 1;
			transition: .2s;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 7px;
			background-color: #E5E5E5;
			border-radius: 5px;
			bottom: 6px;
		}
	}

	&-favorite-button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border: 1px solid #DCDFE6;
		border-radius: 3px;
		width: 240px;
		height: 40px;
		transition: .2s;

		svg {
			width: 17px;
			height: 14px;
			margin-right: 8px;
			margin-top: -3px;
			stroke: #D6DBDE;
			fill: transparent;
			transition: .2s;
		}

		span {
			font-family: 'Gotham Pro Narrow';
			font-weight: 500;
			font-size: 15px;
			line-height: 14px;
			color: #939598;
			transition: .2s;
		}

		&.active {
			border-color: #FB8300;

			svg {
				stroke: #FB8300;
				fill: #FB8300;
			}

			span {
				color: #FB8300;
			}
		}

		&:hover {
			border-color: #FB8300;
		}
	}
}

.modal {
	&-dialog {
		max-width: 400px;

		&-review {
			max-width: 550px;
		}
	}

	&-content {
		border-radius: 0;
		border: none;
		padding: 40px 40px 30px;
	}

	&-title {
		padding-bottom: 15px;

		&__main {
			font-family: 'Gotham Pro Narrow';
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			text-align: center;
			color: #333333;
			padding-bottom: 8px;
		}

		&__sub {
			font-family: 'Gotham Pro';
			font-size: 11px;
			line-height: 16px;
			text-align: center;
			color: #737779;
		}
	}

	&-close {
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 6px;
		background-color: transparent;
		border: none;
		height: 30px;
		width: 30px;

		span {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #737779;
			font-size: 28px;
			transition: .2s;
		}

		&:hover {
			span {
				color: $color-orange;
			}
		}
	}

	&-main {

		.theme-form {
			display: block;

			&__label,
			input[type=submit] {
				height: 50px;
				width: 100%;
				margin-top: 10px;
			}

			&__label {
				span {
					font-size: 15px;
					line-height: 21px;
					top: 13px;
				}

				&.active {
					span {
						top: 4px;
					}
				}

				&-textarea {
					height: 150px;
				}
			}
		}
	}
}

.scroll-tip {
	display: none;
	width: 35px;
	height: 35px;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	right: 15px;
	margin: auto;
	fill: $color-orange;
	animation: move 2.5s ease-out infinite forwards;
	transition: opacity .2s, visibility .2s;

	&__hidden {
		visibility: hidden;
		opacity: 0;
	}
}

@keyframes move {
	0% {
		transform: scale(1);
	}

	35% {
		transform: scale(0.8);
	}

	65% {
		transform: translateX(-70px);
	}

	100% {
		transform: scale(1);
	}
}

.show-after-load {
	visibility: hidden;
}

#circularG {
	position: relative;
	width: 58px;
	height: 58px;
	margin: auto;
}

.circularG {
	position: absolute;
	background-color: $color-orange;
	width: 14px;
	height: 14px;
	border-radius: 9px;
	animation-name: bounce_circularG;
	animation-duration: 1.1s;
	animation-iteration-count: infinite;
	animation-direction: normal;
}

#circularG_1 {
	left: 0;
	top: 23px;
	animation-delay: 0.41s;
}

#circularG_2 {
	left: 6px;
	top: 6px;
	animation-delay: 0.55s;
}

#circularG_3 {
	top: 0;
	left: 23px;
	animation-delay: 0.69s;
}

#circularG_4 {
	right: 6px;
	top: 6px;
	animation-delay: 0.83s;
}

#circularG_5 {
	right: 0;
	top: 23px;
	animation-delay: 0.97s;
}

#circularG_6 {
	right: 6px;
	bottom: 6px;
	animation-delay: 1.1s;
}

#circularG_7 {
	left: 23px;
	bottom: 0;
	animation-delay: 1.24s;
}

#circularG_8 {
	left: 6px;
	bottom: 6px;
	animation-delay: 1.38s;
}

@keyframes bounce_circularG {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(.3);
	}
}


.range-slider-field {
	.irs {
		height: 30px;
		margin-top: -20px;
	}

	.irs-from,
	.irs-to,
	.irs-min,
	.irs-max {
		display: none;
	}

	.irs-line {
		border: none;
		background: #D8D8D8;
		border-radius: 2.5px;
		height: 5px;
	}

	.irs-bar {
		background: $color-orange;
		border: none;
		height: 5px;
	}

	.irs-slider.from,
	.irs-slider.to {
		width: 23px;
		height: 23px;
		background: #FB8300;
		border: none;
		box-shadow: none;
	}

	&__inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 35px 0 0;
		position: relative;

		input {
			background: #FFFFFF;
			border: 1px solid #D7D7D7;
			border-radius: 3px;
			width: 120px;
			height: 40px;
			padding: 10px 15px;
			font-size: 15px;
			line-height: 20px;
			color: #333333;
		}

		&:after {
			content: '';
			display: block;
			width: 40px;
			height: 1px;
			background-color: #D8D8D8;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
		}
	}
}


@media (min-width: 1200px) {
	.container {
		max-width: 1440px;
	}
}

@media (max-width: 1199px) {
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0 15px;
	}

	.theme {
		&-title {
			&__text {
				&-subtitle {
					left: 37%;
				}
			}

			&__link {}

			&-simple {
				padding-bottom: 15px;

				p {
					font-size: 24px;
					line-height: 32px;
				}
			}
		}

		&-form {
			&__personal {
				padding-top: 15px;
			}
		}
	}

	.base {
		&-container {
			padding-top: 50px;
		}
	}

	.range-slider-field {
		&__inputs {
			input {
				width: 90px;
				height: 30px;
				padding: 6px 10px;
				font-size: 14px;
				line-height: 17px;
			}

			&:after {
				width: 22px;
			}
		}
	}
}

@media(max-width: 767px) {
	.scroll-tip {
		display: block;

	}

	.theme {
		&-title {
			&__link {
				display: none;

				&-mobile {
					display: inline-block;
				}

				a {
					span {
						font-size: 14px;
						letter-spacing: 0.17px;
					}
				}
			}
		}

		&-form {
			&__file {
				width: 100%;
				height: 50px;
				flex: 0 0 auto;
				margin-bottom: 5px;

				&-content {
					padding: 10px;
				}
			}

			&__files {
				flex-direction: column;
			}

			label[for="company_desc"] {
				height: 70px;
			}
		}
	}

	.modal {
		&-content {
			padding: 25px;
		}
	}
}


.multibutton {
	position: fixed;
	bottom: 50px;
	right: 77px;
	display: flex;
	flex-direction: column-reverse;
	gap: 10px;
	z-index: 1000;

}

.multibutton-main {
	border-radius: 50%;
	background-color: rgba(251, 131, 0, 0.35);
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

}


.multibutton-wrapper {
	background: rgba(251, 131, 0, 1);
	width: 74px;
	height: 74px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.multibutton-sub {
	position: absolute;
	opacity: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	transform: translateY(20%);
	transition: opacity 1s 1s, transform 1s 1s, visibility 1s 1s;
}

@keyframes ani {
	0% {
		position: static;
		visibility: visible;
		transform: translateY(20%);
		opacity: 0;
	}

	100% {
		position: static;
		transform: translateY(0);
		opacity: 1;
	}
}


.multibutton-sub .multibutton-wrapper {
	width: 60px;
	height: 60px;
	transition: all .3s linear;
}

.multibutton:hover .multibutton-sub {
	animation: ani 1s forwards;

}

.multibutton-text {
	padding: 11px 12px;
	background-color: rgba(234, 174, 109, 1);
	border-radius: 27px;
	font-family: 'Gotham Pro Narrow';
	font-size: 12px;
	font-weight: 700;
	color: rgba(246, 246, 246, 1);
	position: absolute;
	left: -186.65px;
	width: 174px;
	top: 10px;
}

.multibutton-sub-container {
	position: relative;
}

.mb-hover {
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.multibutton-wrapper:hover .mb-hover {
	visibility: visible;
	opacity: 1;
	position: static;
}

.multibutton-wrapper:hover .mb-image {
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.multibutton-sub .multibutton-wrapper:hover {
	background-color: rgba(246, 246, 246, 1);
}

@media(max-width: 900px) {
	.multibutton {
		transform: scale(0.75);
		right: 10px;
		bottom: 20px;
	}
}