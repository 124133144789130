.products-container.loading{
    opacity: 0.5;
}
body.loading-blocks{
    .product-card{
        pointer-events: none;
        margin-bottom: 10px;
        &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            background-image: url('../images/card-big.png');
            background-size: 100% 100%;
        }
    }
    .product-row{
        pointer-events: none;
        margin: 0 0 10px;
        border-bottom: none;
        &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            background-image: url('../images/card-row.png');
            background-size: 100% 100%;
        }
    }
}

.product-card{
    margin-bottom: 20px;
    $this: &;
    padding: 10px;
    position: relative;
    &__content{
        display: block;
    }
    &__thumb{
        width: 100%;
        height: 260px;
        position: relative;
        z-index: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &-no-photo{
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                fill: #bdc0c2;
                width: 150px;
                height: 45px;
            }
        }
    }
    &__info{
        height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding: 16px 10px 10px;
        &-title{
            font-family: 'Gotham Pro';
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #737779;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &-storage{

            text-align: center;
            font-size: 12px;
            line-height: 125%;
            text-align: center;
            color: #737779;
            margin: 0 !important;
            padding: 0 !important;
//             margin-bottom: 10px !important;

            &::before{
                display: inline-block !important;
                position: inherit !important;
                top: auto !important;
                left: auto !important;
                height: 7px !important;
                width: 7px !important;
            }

        }

    }
    &__price{
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }
    &__actions{
        opacity: 0;
        transition: .2s;
        padding: 10px 24px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        .theme-counter, button{
            flex: 0 0 48%;
            height: 50px;
        }
        a.theme-button-reverse{
            flex: 0 0 100%;
            height: 50px;
        }
    }
    &:before{
        opacity: 0;
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background-color: #FFFFFF;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.150486);
        transition: .2s;
    }

    &__labels{
        position: absolute;
        top: 15px;
        left: 0;
    }
    &__label{
        font-family: 'Gotham Pro Narrow';
        font-weight: 700;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.9;
        border: 2px solid;
        border-radius: 10px;
        margin-right: 6px;
        padding: 5px 12px 3px;
        &-hit{
            color: #FB8300;
            border-color: #FB8300;
        }
        &-new{
            color: #FB4700;
            border-color: #FB4700;
        }
        &-sale{
            color: #fbda00;
            border-color: #fbda00;
        }
    }

    &__favorite-add{
        background: transparent;
        border: none;
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 8px;
        width: 30px;
        height: 30px;
        opacity: 0;
        cursor: pointer;
        margin: 10px;
        svg{
            width: 24px;
            height: 20px;
            fill: transparent;
            stroke: #D6DBDE;
            transition: .2s;
        }
        &.active{
            svg{
                fill: #FB8300;
                stroke: #FB8300;
            }
            &:hover{
                svg{
                    fill: #FB4700;
                    stroke: #FB4700;
                }
            }
        }
        &:hover{
            svg{
                stroke: #FB8300;
            }
        }
    }

    &:hover{
        z-index: 99;
        #{$this}__actions, #{$this}__favorite-add, &:before{
            opacity: 1;
        }
    }
}

.price-block{
    $this: &;
    display: inline-block;
    font-family: 'Gotham Pro Narrow', sans-serif;
    &__sale{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        span{
            &:nth-child(1){
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                text-align: right;
                text-decoration-line: line-through;
                color: #C1C1C1;
                padding-right: 5px;
                
            }
            &:nth-child(2){
                font-weight: 700;
                font-size: 11px;
                line-height: 10px;
                color: #FFFFFF;
                padding: 3px;
                background-color: #FB4700;
                border-radius: 2px;
                margin-left: 8px;
            }
        }
    }
    &__price{
        text-align: center;
        span:first-child{
            font-weight: 700;
            font-size: 26px;
            line-height: 24px;
            color: #333333;
        }
        span:nth-child(2){
            display: none;
            font-weight: 500;
            font-size: 12px;
            line-height: 11px;
            text-decoration-line: line-through;
            color: #C1C1C1;
        }
        &.has-old{
            span:first-child{
                color: #FB4700;
            }
        }
    }
    &.sale{
        #{$this}__price{
            span{
                color: #FB4700;
            }
        }
    }
}

.product-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    padding: 14px 10px;
    transition: .2s;
    padding-right: 40px;
    position: relative;
    &__content{
        display: flex;
        flex-direction: row;
        flex: 0 0 45%;
    }
    &__thumb{
        width: 100px;
        height: 75px;
        flex: 0 0 auto;
        margin-right: 30px;
        img{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            object-position: center;
        }
        &-no-photo{
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 100px;
                height: 40px;
                fill: #bdc0c2;
            }
        }
    }
    &__name{

        display: flex;
        flex-direction: column;

        p{
            font-family: 'Gotham Pro';
            font-size: 16px;
            line-height: 24px;
            color: #737779;
            margin-bottom: 8px;
        }
    }

    &__info-storage{

        margin: 0 !important;
        padding: 0 !important;
        font-size: 12px;
        line-height: 125%;
        color: #737779;

        &::before{
            top: auto !important;
            left: auto !important;
            width: 6px !important;
            height: 6px !important;
            display: inline-block !important;
            position: inherit !important;
        }

    }

    &__actions{
        flex: 0 0 45%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .product-card__actions{
            width: 290px;
            opacity: 1 !important;
        }
        .product-card__actions .theme-counter, .product-card__actions button, .product-card__actions .theme-button-reverse{
            height: 40px;
        }

        .price-block__sale{
            padding-bottom: 5px;
        }
        .price-block__price span{
            font-size: 20px;
        }
    }

    .product-card__actions-notify{
        justify-content: flex-end;
        a.theme-button-reverse{
            flex: 0 0 auto !important;
            padding: 0 20px;
        }
    }

    .product-card__favorite-add{
        top: calc(50% - 25px);
        opacity: 1 !important;
    }

    &:hover{
        border-color: #FB8300;
    }
}

.product-section{
    padding-bottom: 160px !important;
}
.product-back{
    margin-bottom: 38px;
}
.product-wrapper{
    padding-left: 20px;
}
.product-slider{
    position: relative;
    &-empty{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: 1px solid #DCDCDC;
        svg{
            fill: #bdc0c2;
            width: 50%;
            height: 20%;
        }
    }
    &.product-slider-empty img{
        width: 50%;
        height:20%;
    }
    &:not(.product-slider-empty){
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 100%;
            top: 18px;
            right: 18px;
            background: url('../images/zoom.svg') 15px 14px/20px no-repeat rgba($color-orange, 0.3);
            pointer-events: none;
            transition: .4s;
        }
        &:hover:after{
            background-color: $color-orange;
        }
    }
    &__container{
        border: 1px solid #DCDCDC;
        &-item{
            height: 375px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
    &__nav{
        margin: 0 -3px;
        &-item{
            cursor: pointer;
            height: 105px;
            max-height: 105px;
            padding: 6px 3px;
            div{
                position: relative;
                border: 1px solid #DCDCDC;
                height: 100%;
                img{
                    width: 100%;
                    height: 100% !important;
                    object-fit: contain;
                    object-position: center;
                }
                &:after{
                    content: '';
                    opacity: 0;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(251, 131, 0, 0.3);
                    transition: .2s;
                }
                &:hover{
                    &:after{
                        opacity: 1;
                    }
                }
            }
            
        }
    }
}
.product-options{
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    padding: 0 10px;
    margin-bottom: 20px;
    overflow: auto;
    position: relative;
    &__header{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #D8D8D8;
        font-family: 'Gotham Pro';
        font-size: 13px;
        line-height: 12px;
        color: #7B7B7B;
        padding: 14px 0;
    }
    &__col{
        justify-content: flex-start;
        &-name{
            flex: 0 0 42%;
        }
        &-price{
            flex: 0 0 19%;
        }
        &-count{
            flex: 0 0 40%;
        }
    }
    &__row{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100px;

        .product-options__col-name{
            span{
                font-family: 'Gotham Pro';
                font-size: 16px;
                line-height: 24px;
                color: #737779;
            }
        }
        .product-options__col-count{
            display: flex;
            flex-direction: row;
            .theme-counter{
                width: 120px;
                height: 40px;
            }
            .theme-button,.theme-button-reverse{
                width: 150px;
                height: 40px;
            }
            .theme-button{
                margin-left: 30px;
            }
        }
        .product-options__col-price{
            .price-block__sale{
                padding-bottom: 5px;
            }
            .product-card__price{
                justify-content: flex-start;
            }
            .price-block__price{
                text-align: left;
            }
            .price-block__price span:first-child{
                font-size: 20px;
            }
        }
    }
}
.product-title{
    font-family: 'Gotham Pro Narrow';
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #333333;
    padding-bottom: 25px;
}
.product-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 12px;
    &__code{
        display: flex;
        flex-direction: row;
        &-code{
            font-family: 'Gotham Pro';
            font-size: 16px;
            line-height: 26px;
            color: #737779;
        }
        &-stock{
            position: relative;
            margin-left: 25px;
            padding-left: 17px;
            span{
                font-family: 'Gotham Pro';
                font-size: 13px;
                color: #7B7B7B;
            }
            &:before{
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 100%;
                left: 0px;
                top: calc(50% - 6px);
            }
            &.yes{
                &:before{
                    background-color: #9BBF2E;
                }
            }
            &.no{
                &:before{
                    background-color: #FB4700;
                }
            }
        }
    }
    &__labels{

    }
}
.product-desc{
    margin-top: 92px;
    border: 1px solid #D8D8D8;
    padding: 50px 80px;
    &__header{
        p{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 24px;
            line-height: 23px;
            color: #333333;
            padding-bottom: 30px;
            position: relative;
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: 110px;
                height: 2px;
                background-color: #FB8300;
            }
        }
    }
    &__body{
        &-text{
            padding: 25px 0 30px;
        }
        &-table{
            width: 55%;
        }
        &-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 10px;
            span{
                font-family: 'Gotham Pro';
                font-size: 18px;
                line-height: 28px;
                &:nth-child(1){
                    font-weight: 400;
                    color: #828282;
                }
                &:nth-child(2){
                    color: #333333;
                    font-weight: 700;
                }
            }
        }
    }
}
.product-order{
    margin-top: 90px;
    background-color: #FCFCFD;
    border: 1px dashed #E5E5E5;
    padding: 0 24px;
    &__block{
        &:not(:last-child){
            border-bottom: 1px solid #E5E5E5;
        }
        padding: 25px 0;
        &-header{
            padding-bottom: 16px;
            svg{
                width: 25px;
                height: 25px;
                fill: #FB8300;
                margin-right: 8px;
            }
            span{
                font-family: 'Gotham Pro Narrow';
                font-weight: 700;
                font-size: 18px;
                line-height: 19px;
                color: #373A3C;
            }
        }
        &-text{
            p{
                font-family: 'Gotham Pro';
                font-size: 15px;
                line-height: 20px;
                color: #999999;
            }
        }
    }
}
.product-similar{
    margin-top: 55px;
    &__title{
        padding: 16px 0;
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 20px;
        p{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 28px;
            line-height: 26px;
            color: #333333;
        }
    }
}
.favorite-remove{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.favorite-removed{
    .product-card__content{
        opacity: 0.6;
    }
    .favorite-remove{
        display: block;
    }
    .sn-remove-from-favorites{
        display: none;
    }
}
@media(max-width: 1199px){
    .product-row{
        padding: 10px 40px 10px 10px;
        &__thumb{
            width: 80px;
            height: 65px;
            margin-right: 10px;
        }
        .product-card{
            &__favorite-add{
                right: 0;
            }
            &__actions{
                width: 265px;
                padding: 10px;
            }
        }
        &__content{
            flex: 0 0 42%;
        }
        &__actions{
            flex: 0 0 53%;
            .price-block{
                &__price{
                    text-align: left;
                    span{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }
        &__name{
            p{
                font-size: 15px;
                line-height: 18px;
            }
        }
    }
    .product-options{
        &__header,&__row{
            min-width: 670px;
        }
        &__col{
            &-name{
                flex: 0 0 34%;
            }
        }
        &__row{
            height: 60px;
        }
        &__header{
            padding: 10px 0;
        }
    }
    .product-desc{
        margin-top: 30px;
        padding: 15px 12px;
        &__header{
            p{
                font-size: 20px;
                padding-bottom: 12px;
            }
        }
        &__body{
            &-text{
                padding: 15px 0 20px;
            }
            &-row{
                padding-bottom: 8px;
                span{
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
    .product-order{
        margin-top: 30px;
        padding: 0 24px;
        &__block{
            padding: 20px 0;
        }
    }
    .product-similar{
        margin-top: 30px;
    }
}
@media (max-width: 767px){
    .product-card{
        padding: 5px 0 0;
        &__content{
            display: flex;
        }
        &__thumb{
            width: 120px;
            height: 93px;
            flex: 0 0 auto;
            position: static;
        }
        &__labels{
            transform-origin: left top;
            transform: scale(.8);
            top: 0;
        }
        &__info{
            height: auto;
            flex: 1 0 59%;
            &-title{
//                 margin-bottom: 10px;
                font-size: 13px;
                line-height: 16px;
                text-align: left;
            }
        }
        &__price{
            justify-content: flex-start;
        }
        &__actions,&__favorite-add{
            display: none;
        }
        &__thumb{
            padding: 10px;
        }
    }
    .price-block{
        &__price{
            text-align: left;
            span:first-child{
                font-size: 18px !important;
                line-height: 1;
            }
            span:nth-child(2){
                display: inline;
            }
        }
        &__sale{
            justify-content: flex-start;
            padding-bottom: 2px;
            span{
                &:nth-child(1){
                    display: none;
                }
                &:nth-child(2){
                    margin: 0;
                }
            }
        }
    }
    .product{
        &-row{
            padding: 8px;
            flex-wrap: wrap;
            .product-card{
                &__favorite-add{
                    display: none;
                }
                &__actions{
                    display: none;
                }
            }
            &__actions,&__content{
                flex: 1 0 100%;
            }
        }
    }
    .product-slider{
        &__container{
            &-item{
                height: 285px;
            }
        }
    }
    .product-wrapper{
        padding: 0;
    }
    .product-title{
        padding-bottom: 18px;
    }

    .product-card__info-storage{
        text-align: start !important;
    }

}
@media (max-width: 575px){
    .product-slider{
        &__container{
            &-item{
                height: 235px;
            }
        }
    }
    .product-info{
        flex-direction: column;
        &__code{
            &-code{
                font-size: 15px;
            }
            &-stock{
                margin-top: 10px !important;
                margin-left: 0px;
            }
        }
    }
    .product-desc{
        &__body{
            &-table{
                width: 100%;
            }
        }
    }

    .product-card{
        &__info{
            &-title{

            }
        }
    }
}

.product-before-inline-block::before{
    display: inline-block;
    position: inherit;
    top: 0;
}