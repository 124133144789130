.categories{
    padding: 40px 20px 30px 0;
    &-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 15px;
        &__thumb{
            width: 55px;
            height: 70px;
            flex: 0 0 55px;
            margin-right: 20px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
            &-empty{
                display: flex;
                align-items: center;
                svg{
                    width: 100%;
                    height: 20px;
                    fill: #bdc0c2;
                }
            }
        }
        span{
            font-family: 'Gotham Pro';
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #333333;
            transition: .2s;
        }
        &:hover{
            span{
                color: #FB8300;
            }
        }
    }
    &-list{
        display: flex;
        flex-direction: column;
        ul>li>a{
            display: inline-block;
            padding: 5px 0;
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 22px;
            color: #333333;
            transition: .2s;
            &:hover{
                color: #FB8300;
            }
        }
    }
    &-hide{
        display: none;
    }
    &-button{
        cursor: pointer;
        padding: 24px 0 0;
        &:hover{
            a{
                span{
                    color: #FB4700;
                }
            }
        }
    }
}


.category-card{
    $this: &;
    display: block;
    font-family: 'Gotham Pro Narrow';
    background-color: #fff;
    padding: 60px 50px;
    cursor: pointer;
    height: 300px;
    transition: .2s;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    &__title{
        display: block;
        max-width: 52%;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #333333;
        padding-bottom: 40px;
        transition: .2s;
        position: relative;
        z-index: 1;
    }
    &__view{
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        color: #FB8300;
        transition: .2s;
        position: relative;
        z-index: 1;
    }
    &__thumb{
        position: absolute;
        height: 100%;
        width: 50%;
        padding: 30px 25px;
        top: 0;
        right: 0;
        z-index: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        width: 280px;
        height: 280px;
        right: -40px;
        bottom: -40px;
        background-color: #fff;
        border-radius: 100%;
        transition: .2s;
    }
    &:hover{
        background-color: #FB8300;
        #{$this}__title, #{$this}__view{color: #fff;}
        &:after{background-color: #FF9A2C;}
    }

    &.big{
        height: calc(100% - 10px);
        #{$this}__thumb{
            bottom: 0;
            width: 90%;
            height: 60%;
            top: auto;
        }
        #{$this}__title{
            max-width: 100%;
        }
        &:after{
            width: 480px;
            height: 480px;
            right: -60px;
            bottom: -90px;
            background-color: #f9f9f9;
        }
        &:hover{
            &:after{
                background:#FF9A2C;
            }
        }
    }
}
@media (max-width: 1199px){
    .category-card{
        padding: 35px;
        height: auto;
        &__title{
            padding-bottom: 15px;
            font-size: 18px;
            line-height: 22px;
        }
        $this: &;
        &.big{
            #{$this}__thumb{
                padding: 15px;
            }
            &:after{
                display: none;
            }
        }
    }
    .categories{
        padding: 20px 0 5px;
        &-title{
            padding-bottom: 5px;
            &__thumb{
                margin-right: 15px;
            }
            span{
                font-size: 16px;
                line-height: 20px;
            }
        }
        &-list{
            ul{
                >li{
                    >a{
                        padding: 3px 0;
                    }
                }
            }
        }
        &-button{
            padding: 3px 0 0;
        }
    }
}
@media(max-width: 767px){
    .category-card{
        height: 140px !important;
        padding: 29px 20px 15px;
        margin-bottom: 5px;
        &__thumb{
            width: 45%;
            top: 0;
            padding: 10px 20px 10px 10px;
        }
        $this: &;
        &.big{
            #{$this}__thumb{
                height: 100%;
                width: 45%;
                top: 0;
                padding: 10px 20px 10px 10px;
            }
            #{$this}__title{
                max-width: 52%;
                padding-bottom: 15px;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}