.filters{

    &-sort{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 25px;
        padding-left: 10px;
        margin-bottom: 20px;
        user-select: none;
        select{display: none;}
        &__parameters{
            $this: &;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 20px;
            &-title{
                color: #737779;
            }
            &-container{
                margin-left: 12px;
                position: relative;
                &.opened{
                    #{$this}-current{
                        &:after{
                            transform: rotate(225deg);
                            top: calc(50% - 2px);
                        }
                    }
                }
            }
            &-current{
                position: relative;
                border: 1px solid #D8D8D8;
                padding-right: 38px;
                width: 235px;
                transition: .2s;
                cursor: pointer;
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    top: calc(50% - 6px);
                    right: 20px;
                    border-right: 2px solid #CACACA;
                    border-bottom: 2px solid #CACACA;
                    transform: rotate(45deg);
                    transition: .2s;
                }
            }
            &-dropdown{
                display: none;
                position: absolute;
                z-index: 999;
                border: 1px solid #D8D8D8;
                left: 0;
                right: 0;
                top: calc(100% + 3px);
                &.opened{
                    display: block;
                }
            }
            &-item{
                cursor: pointer;
                padding: 12px 15px;
                background-color: #FFFFFF;
                svg{
                    width: 10px;
                    height: 10px;
                    fill: #FB8300;
                    margin-right: 6px;
                    margin-top: -4px;
                    &.down{
                        transform: scale(1, -1);
                    }
                }
                span{
                    color: #333333;
                }
            }
            &:hover{
                #{$this}-current{
                    border-color: #FB8300;
                    &:after{
                        border-color: #FB8300;
                    }
                }
            }
        }
        &__view{
            position: relative;
            border: 1px solid #D8D8D8;
            height: 47px;
            button{
                cursor: pointer;
                width: 45px;
                height: 45px;
                background-color: #fff;
                border: none;
                transition: .2s;
                svg{
                    fill: #BDBEBF;
                    width: 14px;
                    height: 14px;
                }
                &.active{
                    svg{
                        fill: #FB8300;
                    }
                }
            }
            &:after{
                content: '';
                display: block;
                height: calc(100% - 8px);
                width: 1px;
                position: absolute;
                left: calc(50% - 1px);
                top: 4px;
                background-color: #D8D8D8;
            }
        }
    }

    &-categories{
        background-color: #EEF1F4;
        margin-bottom: 10px;
        &__header{
            padding: 15px 25px;
            border-bottom: 1px solid #fff;
            p{
                font-family: 'Gotham Pro Narrow';
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #333333;
            }
        }
        &__body{
            &-wrapper{
                padding: 12px 25px 20px;
                ul{
                    li{
                        a{
                            display: inline-block;
                            padding: 8px 0;
                            font-family: 'Gotham Pro';
                            font-size: 15px;
                            line-height: 20px;
                            color: #333333;
                            transition: .2s;
                            &:hover{
                                color: #9D9D9D;
                            }
                        }
                    }
                }
            }
        }
    }

    &-ajax{
        border: 1px solid #D8D8D8;
        padding: 0 10px;
    }

    &-block{
        &:not(:last-child){
            border-bottom: 1px solid #D8D8D8;
        }
        &__header{
            display: block;
            position: relative;
            padding: 15px;
            span{
                font-family: 'Gotham Pro Narrow';
                font-weight: 500;
                font-size: 17px;
                line-height: 22px;
                color: #333333;
                transition: .2s;
            }

            &:after{
                content: '';
                display: block;
                position: absolute;
                width: 8px;
                height: 8px;
                top: calc(50% - 2px);
                right: 15px;
                transform: rotate(-135deg);
                border-bottom: 1px solid #CACACA;
                border-right: 1px solid #CACACA;
                transition: .2s;
            }

            &.collapsed{
                &:after{
                    top: calc(50% - 6px);
                    transform: rotate(45deg);
                }
            }

            &:hover{
                span{
                    color: #FB4700;
                }
                &:after{
                    border-color: #FB4700;
                }
            }
        }
        &__body{
            padding: 0 15px;
            &-wrapepr{
                padding: 0 0 18px;
            }
            &-item{
                padding: 8px 0;
            }
        }
        
        &__scroll{
            max-height: 300px;
            overflow: auto;
            padding-right: 5px;
            margin-top: 5px;
            &::-webkit-scrollbar{
                width: 2px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                border: 3px solid orange;
            }
            &::-webkit-scrollbar-track {
                background: #EEF1F4;        /* цвет зоны отслеживания */
              }
        }
    }

    &-footer{
        cursor: pointer;
        padding: 12px 15px 20px;
        span{
            display: inline-flex;
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 26px;
            color: #A3A3A3;
            border-bottom: 1px dashed #A3A3A3;
            transition: .2s;
        }
        &:hover{
            span{
                color: #FB4700;
                border-color: #FB4700;
            }
        }
    }
}

.custom-label{
    $this: &;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    &-input{
        display: none;
        &:checked + #{$this}-checkbox{
            background-color: #FB8300;
            border-color: #FB8300;
            background-image: url('../images/custom-label-check.svg');
        }
    }
    &-checkbox{
        flex: 0 0 18px;
        display: block;
        background-color: #FFFFFF;
        background-position: center;
        background-size: 10px;
        background-repeat: no-repeat;
        border: 1px solid #D7D7D7;
        border-radius: 2px;
        width: 18px;
        height: 18px;
        margin-right: 12px;
        transition: .2s;
    }
    &-text{
        flex: 1 0 auto;
        font-family: 'Gotham Pro';
        font-size: 15px;
        line-height: 20px;
        color: #333333;
        transition: .2s;
    }
    &:hover{
        #{$this}-input:not(:checked) + #{$this}-checkbox{
            border-color: #FB4700;
        }
        #{$this}-text{
            color: #FB4700;
        }
    }
}

.pagination{
    &-block{
        margin-top: 30px;
        &__more{
            cursor: pointer;
            width: 100%;
            height: 50px;
            background: rgba(240, 240, 240, 0.5);
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 14px;
            line-height: 13px;
            letter-spacing: 0.77px;
            text-transform: uppercase;
            color: #333333;
            border: none;
            margin-bottom: 30px;
            transition: .2s;
            &:hover{
                color: #fff;
                background-color: #FB8300;
            }
            &.blocked{
                pointer-events: none;
            }
        }
        &__nav{
            display: flex;
            justify-content: center;
            ul{
                display: flex;
                flex-direction: row;
                border: 1px solid #DCDFE6;
                li{
                    line-height: 1;
                    a, span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        font-family: 'Gotham Pro';
                        font-size: 16px;
                        line-height: 1;
                        text-align: center;
                        color: #2D303F;
                        font-weight: 500;
                    }
                    a{
                        transition: .4s;
                        &:hover{
                            color: #fff;
                            background-color: #FB8300;
                        }
                    }
                    &:not(:last-child){
                        >a, >span{
                            border-right: 1px solid #DCDFE6;
                        }
                    }
                    &.active{
                        a, span{
                            color: #fff;
                            background-color: #FB8300;
                        }
                    }
                }
            }
        }
    }
}
.products-container{
    &--loading{
        opacity: .6;
        pointer-events: none;
    }
}
@media (max-width: 1199px){
    .filters{
        &-categories{
            &__header{
                padding: 12px 18px;
                p{
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            &__body-wrapper{
                padding: 10px 18px 12px;
                ul li a{
                    padding: 5px 0;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        &-ajax{
            padding: 0 6px;
        }
        &-block{
            &__header{
                padding: 11px 8px;
                span{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            &__body{
                padding: 0 8px;
                &-wrapepr{
                    padding: 0 0 10px;
                }
                &-item{
                    padding: 6px 0;
                }
            }
        }
        &-footer{
            padding: 10px 8px 12px;
            span{
                line-height: 20px;
            }
        }
        &-sort{
            padding: 0 0 12px 0;
            margin-bottom: 14px;
            &__parameters{
                font-size: 14px;
                line-height: 16px;
                &-current{
                    padding-right: 22px;
                    &:after{
                        right: 10px;
                    }
                }
                &-item{
                    padding: 10px 8px;
                }
            }
        }
    }
    .pagination{
        &-block{
            margin-top: 15px;
            &__more{
                height: 40px;
                margin-bottom: 15px;
            }
            &__nav{
                ul{
                    li{
                        a,span{
                            width: 30px;
                            height: 30px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 991px){
    .filters{
        &-sort{
            margin-top: 10px;
            flex-wrap: wrap;
            &__parameters{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 8px;
                &-container{
                    flex: 1 0 auto;
                    margin-left: 8px;
                }
                &-current{
                    width: auto;
                }
            }
        }
    }
}