.service{
	&-row{
		&-left{
			div:first-child{
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
		&-right{
			div:last-child{
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
	&-card{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 350px;
		padding: 30px;
		margin-bottom: 10px;
		position: relative;
		background: center/cover no-repeat #EEF1F4;
		&-title{
			position: relative;
			z-index: 1;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			text-align: center;
			color: #fff;
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 142px;
				height: 10px;
				max-width: 100%;
				margin: auto;
				z-index: -1;
				border-radius: 5px;
				background-color: transparent;
				transition: background-color .4s;
			}
		}
		&-col{
			padding: 0 5px;
			flex: 0 0 25%;
			max-width: 25%;
			// &:nth-child(3n){
				
			// }
			&-big{
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(#000,.4);
			transition: background-color .4s;
		}
		$this: &;
		&:hover{
			&:before{
				background-color: rgba($color-orange,.95);
			}
			#{$this}-title{
				&:before{
					background-color: #FB4700;
				}
			}
		}
	}
}
@media (max-width: 1199px){
	.service{
		&-card{
			height: 270px;
			&-col{
				flex: 0 0 33.3%;
				max-width: 33.3%;
			}
			&-title{
				font-size: 20px;
				line-height: 28px;
			}
		}
		&-row{
			&-left{
				.service-card-col:first-child{
					flex: 0 0 33.3%;
					max-width: 33.3%;
				}
			}
			&-right{
				.service-card-col:last-child{
					flex: 0 0 33.3%;
					max-width: 33.3%;
				}
			}
		}
	}
}
@media (max-width: 575px){
	.service{
		&-card{
			height: auto;
			&-col{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		&-row{
			&-left{
				.service-card-col:first-child{
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
			&-right{
				.service-card-col:last-child{
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
}