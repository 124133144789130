.calculator {
  background-color: transparent;
  border: 1px solid #E0E0E0;
  padding: 40px 0;

  input {
    display: none;
  }

  .calc_header {
    padding: 30px 70px;
    padding-top: 0;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: #2E2E2E;
      margin-bottom: 10px;
    }

    span {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #71787C;
    }
  }

  .calc_body {
    .fixed {
      background: #F4F5F5;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #2E2E2E;
      margin: 0 !important;
      padding: 25px 70px;

      label {
        width: 100%;
      }
    }

    .calc_bloc {

      border-bottom: 1px solid #E0E0E0;
      margin: 25px 70px;
      padding-bottom: 30px;

      &:last-child {
        padding-bottom: 0;
        border: none;
      }

      .calc_title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #2E2E2E;
        margin-bottom: 20px;
      }
    }

    .calc_items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
  }

  label {
    width: 47%;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;

    // &:last-child {
    //   margin-bottom: 0;
    // }

    span {
      margin-left: auto;
    }

    .category-filter-group-item-marker {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #F56E4F;
      position: absolute;
      top: -1px;
      left: 0;
      
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #F56E4F;
        width: 12px;
        height: 12px;
       
        display: block;
        opacity: 0;
      }
    }

    .calc_item-descr {
      width: 90%;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #71787C;
      margin-top: 3px;
      margin-left: 0;
    }

  }

 

  input:checked+span .category-filter-group-item-marker svg {
    opacity: 1;
  }

  .calc_footer {
    display: flex;
    margin: 0 45px;
    background: rgba(251, 131, 0, 0.1);
    padding: 0 40px;
    justify-content: space-between;
    height: 90px;
    align-items: center;
    margin-top: 40px;

    .total_price {
      display: flex;
      align-items: center;

      output {
        margin-left: 20px;
        font-weight: bold;
        font-size: 26px;
        line-height: 27px;
        color: #2E2E2E;
      }
    }

    .calc_btn {
      background: #FB8300;
      border-radius: 4px;
      color: white;
      padding: 12px 50px;
    }
  }

}

@media (max-width: 1199px) {
  .calculator {

    .calc_header,
    .calc_body .calc_bloc {
      padding: 20px;
      margin: 0;
    }

    .calc_footer {
      margin: 0;
      margin-top: 20px;
    }

  }
  .calculator {
    label {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
 
}

@media (max-width: 767px) {
  .calculator {
    padding: 0;
  }

  .calculator .calc_footer {
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .calc_btn {
      margin-top: 10px;
    }
  }
}
@media (max-width: 575px) {
  .calculator{
    .calc_header .title{
      font-size: 18px;
    line-height: 18px;
    }
    .calc_header span{
      font-size: 14px;
    line-height: 14px;
    }
    label {
      padding-left: 15px;
      font-size: 14px;
      .category-filter-group-item-marker {
        width: 10px;
        height: 10px;
        svg{
          height: 4px;
          width: 7px;
        }
      }
    }
  }
  .calculator .calc_header, .calculator .calc_body .calc_bloc {
    padding: 20px 8px;
  }
  .total_price {
    p{
      font-size: 13px;
    }
    output {
      font-size: 20px;
      line-height: 20px;
    }
  }

}