.notify{
    $this: &;
    border: 1px solid #D0D0D0;
    padding: 35px 30px;
    width: 100%;
    &-body{
        display: flex;
        flex-direction: row;
    }
    &-icon{
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        margin-right: 35px;
        margin-top: -5px;
        fill: #FB8300;
        &--red{
            fill: #FB4700;
        }
    }
    &-title{
        font-family: 'Gotham Pro Narrow';
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #333333;
        padding-bottom: 10px;
    }
    &-desc{
        font-family: 'Gotham Pro';
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        a{
            display: inline-block;
            color: #FB8300;
            border-bottom: 1px solid;
            transition: .2s;
            &:hover{
                color: #FB4700;
            }
        }
    }

    &.favorite{
        #{$this}-icon{
            stroke: #FB8300;
            fill: transparent;
        }
    }
}

@media(max-width: 575px){
    .notify{
        padding: 20px 15px;
        &-icon {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            margin-right: 20px;
        }
        &-title{
            font-size: 20px;
            padding-bottom: 5px;
        }
        &-desc{
            font-size: 14px;
        }
    }
}