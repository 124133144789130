.cart{
    &-table{
        position: relative;
        border: 1px solid #D0D0D0;
        padding: 0 20px;
        margin-bottom: 30px;
        overflow: auto;
        &__header{
            display: flex;
            flex-direction: row;
            min-width: 1040px;
            padding: 18px 0;
            border-bottom: 1px solid #D0D0D0;
            span{
                font-family: 'Gotham Pro';
                font-size: 13px;
                line-height: 12px;
                color: #7B7B7B;
            }
        }
        &__col{
            &-name{
                flex: 0 0 40%;
            }
            &-price{
                flex: 0 0 20%;
            }
            &-count{
                flex: 0 0 25%;
            }
            &-total{
                flex: 0 0 15%;
            }
        }
        &__row{
            min-width: 1040px;
            &:not(:last-child){
                border-bottom: 1px solid #D0D0D0;
            }
            &-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 0;
                position: relative;
            }
        }
    }
    &-block{
        &__name{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 30px;
            &-img{
                width: 110px;
                height: 80px;
                margin-right: 18px;
                flex: 0 0 110px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
                &-empty{
                    display: flex;
                    align-items: center;
                    svg{
                        fill: #bdc0c2;
                        width: 100%;
                        height: 40px;
                    }
                }
            }
            &-title{
                font-family: 'Gotham Pro';
                p{
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                }
                span{
                    font-size: 14px;
                    line-height: 24px;
                    color: #737779;
                }
            }
        }
        &__price{
            .price-block{
                &__sale{
                    justify-content: flex-start;
                    padding-bottom: 5px;
                    span{
                        &:nth-child(2){
                            margin: 0;
                        }
                    }
                }
                &__price{
                    text-align: left;
                    span{
                        &:nth-child(1){
                            font-size: 20px;
                            line-height: 19px;
                        }
                        &:nth-child(2){
                            display: inline !important;
                            color: #C1C1C1 !important;
                        }
                    }
                }
            }
        }
        &__count{
            .theme-counter{
                width: 120px;
                height: 40px;
            }
        }
        &__del{
            cursor: pointer;
            position: absolute;
            top: calc(50% - 14px);
            right: 20px;
            width: 24px;
            height: 24px;
            padding: 5px;
            background-color: transparent;
            border: none;
            svg{
                width: 100%;
                height: 100%;
                fill: #C3C3C3;
                vertical-align: top !important;
                transition: .2s;
            }
            &:hover{
                svg{
                    fill: #FB8300;
                }
            }
        }
    }
    &-sidebar{
        position: relative;
    }
    &-check{
        max-width: 470px;
        padding: 25px;
        background-color: #fff;
        border: 1px solid #D0D0D0;
        &__title{
            font-family: 'Gotham Pro Narrow';
            font-weight: 500;
            font-size: 18px;
            line-height: 17px;
            color: #333333;
        }
        &__result{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 25px 0;
            margin: 0 0 24px;
            border-bottom: 1px solid #D0D0D0;
            &-total{
                font-family: 'Gotham Pro Narrow';
                font-weight: 700;
                font-size: 20px;
                line-height: 19px;
                color: #333333;
            }
        }
        &__list{
            // Fix
            max-height: 500px;
            overflow-y: scroll;
            padding-right: 15px;
            //

            padding: 17px 0 25px;
            border-bottom: 1px solid #D0D0D0;
            &-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                span{
                    flex: 0 0 70%;
                    font-family: 'Gotham Pro';
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                    &>em{
                        display: block;
                        font-size: 14px !important;
                        line-height: 24px !important;
                        color: #737779 !important;
                        font-style: normal;
                    }
                }
                b{
                    flex: 0 0 25%;
                    text-align: right;
                    font-family: 'Gotham Pro Narrow';
                    font-size: 14px;
                    line-height: 13px;
                    color: #333333;
                    &.sale{
                        color: #FB8300;
                    }
                }
            }
        }
        &__ready{
            a{
                width: 100%;
                height: 50px;
            }
        }
    }

    &-alert{
        display: flex;
        background-color: #FCFCFD;
        border: 1px dashed #E5E5E5;
        padding: 18px 25px;
        svg{
            flex: 0 0 35px;
            width: 35px;
            height: 35px;
            fill: #FB8300;
        }
        p{
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 20px;
            color: #999999;
            margin-left: 25px;
        }
    }
}

.order{
    &-form{
        &__item{
            padding-bottom: 30px;
            margin-bottom: 50px;
            margin-right: 35px;
            &:not(:last-child){
                border-bottom: 1px solid #D0D0D0;
            }
        }
        &__header{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #333333;
            padding-bottom: 45px;
            span{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background-color: #DAE0E6;
                font-family: 'Gotham Pro';
                font-weight: 700;
                font-size: 20px;
                border-radius: 100%;
                margin-right: 15px;
            }
            p{
                font-family: 'Gotham Pro Narrow';
                font-weight: 700;
                font-size: 24px;
                line-height: 23px;     
            }
        }
        &__body{

        }

        &__row{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 20px;
            &-label{
                flex: 0 0 50%;
                font-family: 'Gotham Pro';
                font-size: 16px;
                line-height: 15px;  
                color: #333333;
                cursor: pointer;
            }
            &-input{
                position: relative;
                flex: 0 0 50%;
                input{
                    background-color: #FFFFFF;
                    border: 1px solid #D0D0D0;
                    border-radius: 3px;
                    height: 50px;
                    font-family: 'Gotham Pro';
                    font-size: 16px;
                    line-height: 15px;  
                    color: #333333;
                    width: 100%;
                    padding: 0 15px;
                    &.invalid{
                        border-color: #FB4700;
                    }
                }
                &.s{
                    flex: 0 0 25%;
                }
                span{
                    display: none;
                    position: absolute;
                    font-size: 14px;
                    line-height: 14px;
                    color: #FB4700;
                    top: calc(100% + 4px);
                    &.active{
                        display: block;
                    }
                }
            }
        }

        &__radio{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 -5px;
            padding-bottom: 50px;
            input{
                display: none;
                &:checked + label{
                    background-color: #FB8300;
                    border-color: #FB8300;
                    p, span{
                        color: #fff; 
                    }
                }
                &:not(:checked) + label:hover{
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.150486);
                    border-color: #fff;
                }
            }
            &-block{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                margin: 0 5px;
                cursor: pointer;
                padding: 15px 15px;
                background-color: #FFFFFF;
                border: 1px solid #D0D0D0;
                border-radius: 3px;
                transition: .2s;
                p{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #333333;
                }
                span{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #333333;
                    margin: 8px 0 0;
                }
            }
        }
    }
}
.sending-order{
    opacity: .6;
    pointer-events: none;
}
@media (max-width: 1199px){
    .cart{
        &-check{
            padding: 16px;
            max-width: 318px;
            &__list{
                padding: 12px 0 18px;
                &-row{
                    padding-bottom: 8px;
                }
            }
            &__result{
                padding: 15px 0;
                margin: 0 0 20px;
                &-total{
                    font-size: 18px;
                }
            }
            &__title{
                font-size: 17px;
            }
        }
        &-alert{
            padding: 14px 13px;
            p{
                margin-left: 20px;
            }
        }
        &-table{
            padding: 0 10px;
            &__col{
                padding-right: 15px;
                &-total{
                    flex: 0 0 auto;
                }
                &-price{
                    flex: 0 0 14%;
                }
                &-count{
                    flex: 0 0 21%;
                }
            }
            &__row,&__header{
                min-width: 635px;
            }
        }
        &-block{
            &__name{
                padding-right: 0;
                flex: 0 0 46%;
                &-img{
                    width: 90px;
                    flex: 0 0 90px;
                    margin-right: 14px;
                }
                &-title{
                    p{
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
            }
        }

    }
    .order{
        &-form{
            &__row{
                padding-bottom: 12px;
            }
            &__item{
                margin-right: 0;
                margin-bottom: 25px;
                padding-bottom: 15px;
            }
            &__header{
                padding-bottom: 18px;
                p{
                    font-size: 18px;
                    line-height: 22px;
                }
                span{
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                }
            }
            &__radio{
                padding-bottom: 25px;
                &-block{
                    padding: 10px;
                }
            }
        }
    }
}
@media (max-width: 991px){
    .cart-table, .cart-alert{
        margin-bottom: 15px;
    }
    .cart{
        &-check{
            max-width: unset;
        }
    }
}
@media (max-width: 575px){
    .order{
        &-form{
            &__row{
                &-label{
                    flex: 0 0 48%;
                    padding-right: 10px;
                    line-height: 17px;
                }
                &-input{
                    &.s{
                        flex: 0 0 50%;
                    }
                }
            }
            &__radio{
                flex-direction: column;
                &-block{
                    &:not(:last-child){
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}