@import "../_mixin_test";

.index-part{
    &.slider{
        padding: 20px 0;
    }

    &.promo{
        padding: 52px 0;
        .theme-title{
            padding-bottom: 70px;
        }
    }

    &.categories{
        background-color: #EEF1F4;
        padding: 55px 0 70px;
        .theme-title{
            padding-bottom: 55px;
            p{
                text-align: center;
                color: #DBE1E6;
            }
            span{
                left: 0;
            }
            &__link{
                bottom: 14px;
            }
        }   
    }

    &.service{
        background-color: #DAE0E6;
        padding: 70px 0;
        .theme-title{
            padding-bottom: 92px;
            p{
                color: #FFFFFF;
            }
            span{
                left: 17%;
                color: $color-orange;
            }
        }
    }

    &.news{
        padding: 60px 0 90px;
    }

}

.main-slider{
    $this: &;
    height: calcFluidSize(135, 600, 320, 1920);
    overflow: hidden;
    .slick-slide{
        #{$this}__slide{
            transform: scale(1.2);
        }
        &.slick-active{
            #{$this}__slide{
                transform: scale(1);
            }
        }
    }
    &__slide{
        height: calcFluidSize(135, 600, 320, 1920);
        background-position: center;
        background-size: cover;
        transition: 900ms cubic-bezier(0.7, 0, 0.3, 1) 0s;
        overflow: hidden;
    }
    &__content{
        width: 600px;
        padding: 60px 0 120px 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-title{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 70px;
            line-height: 84px;
            color: #FFFFFF;
        }
        &-info{
            .price{
                font-family: 'Gotham Pro Narrow';
                font-weight: 500;
                font-size: 30px;
                line-height: 28px;
                color: #FFFFFF;
            }
            ul{
                li{
                    display: block;
                    position: relative;
                    font-family: 'Gotham Pro';
                    font-size: 18px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        background-color: #FB4700;
                        border-radius: 100%;
                        left: -20px;
                        top: calc(50% - 3px);
                    }
                }
            }
        }
        &-link{
            a{
                position: relative;
                display: inline-block;
                span{
                    position: relative;
                    z-index: 1;
                    font-family: 'Gotham Pro Narrow';
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 17px;
                    letter-spacing: 0.218571px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: #FB4700;
                    border-radius: 5px;
                    width: calc(100% + 6px);
                    height: 10px;
                    left: -3px;
                    bottom: 2px;
                }
            }
        }
    }

    &__nav{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 55px;
        padding: 0 25px;
        bottom: 45px;
        left: 0;
        height: 0px;
        &-dots{
            ul{
                display: flex;
                flex-direction: row;
                align-items: center;
                li{
                    cursor: pointer;
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: #FFFFFF;
                    opacity: 0.7;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.167122);
                    border-radius: 100%;
                    transition: .2s;
                    margin-right: 10px;
                    button{
                        display: none;
                    }
                    &.slick-active{
                        opacity: 1;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        &-arrows{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 120px;
            .slick-arrow{
                cursor: pointer;
                width: 55px;
                height: 55px;
                background-color: #fff;
                border-radius: 100%;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.167122);
                opacity: .7;
                transition: .2s;
                position: relative;
                &:after{
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 0 7px 8px;
                    border-color: transparent transparent transparent #737779;
                    position: absolute;
                    left: 0;
                    right: -2px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: .2s;
                }
                &__prev{
                    transform: rotate(180deg);
                }
                &:hover{
                    opacity: 1;
                    &:after{
                        border-color: transparent transparent transparent $color-orange;
                    }
                }
            }
        }
    }
}

.promo{
    $slider_speed: .5s;

    overflow: hidden;
    &-slider{
        @include _768 {
            height: 500px;
            overflow: hidden;

            &.c1 {
                height: 120px;
            }

            &.c2 {
                height: 230px;
            }

            &.c3 {
                height: 350px;
            }
        }

        &__container{
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;
            .product-card {
                flex: 0 0 24%;
            }
        }
        .slick-list{
            overflow: visible;
        }
        .slick-slide{
            margin: 0 10px;
            opacity: 0;
            pointer-events: none;
            transition: $slider_speed;
            &.slick-active{
                opacity: 1;
                pointer-events: all;
            }
        }
        &__nav{
            padding: 47px 0 50px;
            position: relative;
            .slick-dots{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                li{
                    display: block;
                    background-color: #E3E3E3;
                    border-radius: 5px;
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    transition: $slider_speed;
                    button{
                        display: none;
                    }
                    &.slick-active{
                        width: 50px;
                        background-color: $color-orange;
                    }
                }
            }
            .slick-arrow{
                width: 55px;
                height: 55px;
                background-color: #fff;
                border-radius: 100%;
                border: 1px solid #BCBCBC;
                transition: .2s;
                position: absolute;
                top: calc(50% - 28px);
                &:after{
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 0 7px 8px;
                    border-color: transparent transparent transparent #737779;
                    position: absolute;
                    left: 0;
                    right: -2px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: .2s;
                }
                &__prev{
                    transform: rotate(180deg);
                }
                &__next{
                    right: 0;
                }
                &:not(.slick-disabled){
                    cursor: pointer;
                    &:hover{
                        border-color: transparent;
                        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.167122);
                        &:after{
                            border-color: transparent transparent transparent $color-orange;
                        }
                    }
                }

                &.slick-disabled{
                    box-shadow: none;
                    opacity: .5;
                }
            }
        }
    }
}

.service{
    &-wrapper{
        position: relative;
        &:before{
            content: '';
            display: block;
            position: absolute;
            left: -75px;
            top: 0;
            width: 75px;
            height: 100%;
            background-color: #DAE0E6;
            z-index: 2;
        }
    }
    &-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 0;
        left: -35px;
        position: relative;
        &__item{
            $this: &;
            position: relative;
            margin-bottom: 56px;
            padding: 0 30px;
            &-title{
                cursor: pointer;
                display: inline-block;
                font-family: 'Gotham Pro Narrow';
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                color: #69737D;
                position: relative;
                z-index: 1;
                display: inline-block;
                padding: 6px;
                background-color: transparent;
                border-radius: 3px;
                transition: .2s;
            }
            &-image{
                position: absolute;
                display: block;
                width: 280px;
                height: 280px;
                top: calc(50% - 140px);
                left: calc(50% - 140px);
                transform: scale(0);
                transition: .2s;
                transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
                z-index: 3;
                filter: brightness(0.6);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            &:before{
                content: '';
                display: block;
                height: 48px;
                width: 2px;
                background-color: #BBC2C9;
                position: absolute;
                left: -5px;
                transform: skewX(-15deg);
                top: 0;
            }

            &:hover{
                #{$this}-title{
                    background-color: $color-orange;
                    color: #fff;
                    z-index: 5;
                }
                #{$this}-image{
                    transform: scale(1);
                }
            }
        }
    }
}

.feedback{
    padding: 72px 0 0;
    &-image{
        width: 100%;
        height: 680px;
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 150px;
            height: 150px;
            background-image: url('../images/feedback-decor-left.svg');
            background-repeat: no-repeat;
            top: 52px;
            left: -11%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left;
            position: relative;
            z-index: 1;
        }
    }
    &-content{
        padding: 32px 0 0;
    }
    &-text{
        position: relative;
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 240px;
            height: 225px;
            background-image: url('../images/feedback-decor-right.png');
            background-repeat: no-repeat;
            background-size: contain;
            top: 100%;
            right: -21%;
        }
        &__help{
            font-family: 'WeblySleek UI';
            font-style: italic;
            font-weight: 300;
            font-size: 30px;
            line-height: 40px;
            color: $color-orange;
        }
        &__title{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 76px;
            line-height: 84px;
            color: #333333;
            padding: 20px 0 26px;
        }
        &__send{
            font-family: 'Gotham Pro';
            font-size: 18px;
            line-height: 28px;
            color: #737779;
            width: 500px;
        }
    }
    &-form{
        padding-top: 52px;
        label, input[type=submit]{
            height: 60px;
            width: 230px;
        }
        label{
            margin-right: 10px;
        }
    }
}

.partners{
    position: relative;
    display: flex;
    justify-content: center;
    &-wrapper{
        display: flex;
        flex: 0 0 50%;
        padding: 65px 0 75px;
        .theme-title{
            padding-bottom: 45px;
        }
        .theme-title__link{
            padding-top: 50px;
            position: static;
            display: inline-flex;
            a:after:not(:hover){
                background-color: #D2D8DB;
            }
            // a:hover:after{
            //     background-color: $color-orange;
            // }
        }
        &__left{
            justify-content: flex-end;
            padding-right: 55px;
            background-color: #DAE0E6;
            .theme-title__text-title{
                color: #fff;
            }
        }
        &__right{
            padding-left: 55px;
            background-color: #EEF1F4;
            .theme-title__text-title{
                color: #DBE1E6;
            }
        }
        &__content{
            max-width: 660px;
        }
    }
    &-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item{
            width: 150px;
            height: 112px;
            padding: 10px;
            margin-bottom: -15px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

}

.index-content{
    margin-top: 80px;
    @media (max-width:1200px){
        margin-top: 60px;
    }
    @media (max-width: 700px){
        margin-top: 40px;
    }
}

@media(max-width: 1199px){
    .index-part.slider{
        padding: 0;
        .container, .row, .col-12{
            padding: 0;
            margin: 0;
        }
    }
    .main-slider__nav{
        justify-content: center;
        bottom: -25px;
        height: 20px;
    }
    .main-slider__nav-arrows{
        display: none;
    }
    .main-slider__nav-dots ul li{
        box-shadow: none;
        background-color: #E3E3E3;
        width: 6px;
        height: 6px;
    }
    .main-slider__nav-dots ul li.slick-active{
        background-color: $color-orange;
        width: 8px;
        height: 8px;
    }
    .promo{
        &-slider{
            &__nav{
                height: 40px;
                padding: 0;
                .slick{
                    &-dots{
                        display: none !important;
                    }
                    &-arrow{
                        width: 40px;
                        height: 40px;
                        top: 0;
                        &__prev{
                            left: unset;
                            right: 50px;
                        }
                    }
                }
                .theme-title__link{
                    right: unset;
                    left: 0;
                    top: 5px;
                }
            }
            &__container{
                flex-wrap: wrap;
                .product-card{
                    flex: 0 0 50%;
                }
            }
            .slick-slide{
                margin: 0;
            }
        }
    }
    .index-part.categories{
        padding: 26px 0 37px;
        .theme-title{
            padding-bottom: 21px;
        }
    }
    .index-part.service{
        padding: 30px 0;
        .theme-title{
            padding-bottom: 32px;
        }
    }
    .service{
        &-container{
            padding: 0;
            left: 0;
            flex-direction: column;
            &__item{
                padding: 0 0 0 15px;
                margin-bottom: 20px;
                &-title{
                    padding: 0;
                    font-size: 16px;
                    line-height: 26px;
                }
                &-image{
                    display: none;
                }
                &:before{
                    height: 28px;
                    left: 0;
                }
            }
        }
        &-wrapper{
            &:before{
                display: none;
            }
        }
    }
    .feedback{
        padding: 18px 0 50px;
        &-image{
            height: auto;
            position: relative;
            left: 20px;
            img{
                max-height: 400px;
            }
            &:after{
                height: 56px;
                width: 56px;
                left: -6%;
                top: 20px;
                background-size: cover;
            }
        }
        &-text{
            &__help{
                font-size: 20px;
                line-height: 27px;
            }
            &__title{
                padding: 11px 0 15px;
                font-size: 34px;
                line-height: 42px;
            }
            &__send{
                width: auto;
                font-size: 13px;
                line-height: 21px;
            }
            &:after{
                display: none;
            }
        }
        &-form{
            padding-top: 25px;
            .theme-form{
                flex-direction: column;
            }
            label{
                margin: 0 0 10px;
            }
            label,input[type=submit]{
                width: 290px;
            }
        }
    }
    .partners{
        &-wrapper{
            padding: 35px 15px;
            .theme-title{
                padding-bottom: 12px;
                &__link{
                    padding-top: 5px;
                }
            }
            &__content{
                width: 100%;
            }
        }
        &-container{
            &__item{
                width: 131px;
                height: 78px;
                padding: 8px;
                margin-bottom: 10px;
            }
        }
    }
    .index-part.news{
        padding: 30px 0 40px;
    }
    .index-part.promo{
        .theme-title{
            padding-bottom: 28px;
        }
    }
}
@media (max-width: 767px){
    .promo{
        &-slider{
            &__container{
                flex-direction: column;
                .product-card{
                    flex: 0 0 auto;
                }
            }
        }
    }
    .index-part.categories{
         .theme-title{
            p{
                text-align: right;
            }
            &__link{
                &-mobile{
                    margin-top: 21px;
                }
            }
         }
    }
    .index-part.service{
        .theme-title{
            span{
                left: 34%;
            }
        }
    }
    .feedback{
        &-content{
            padding: 0;
        }
        &-form{
            label,input[type=submit]{
                width: 100%;
            }
            .theme-form{
                width: 100%;
            }
        }
    }
    .partners{
        flex-direction: column;
        &-wrapper{
            flex: 0 0 auto;
            padding: 35px 15px;
        }
        &-container{
            &__item{
                width: 131px;
                height: 78px;
                margin-bottom: 10px;
            }
        }
    }
    .section-category-slider {
        padding: 0;
    }
    .slick-slide {
        height: 35vw;
        img {
            width: 100%;
            height: 35vw;
        }
    }
}




@media (min-width: 1920px){
    .index-part .slick-slide {
        height: 25vw;
        img {
            width: 100%;
            height: 25vw;
        }
    }

    .index-part .slick-slide {
        height: 25vw;
        .promo-slider__container{
            img {
                width: 100%;
                height: auto;
                max-height: 260px;
            }
        }
    }
}

.main-slider .slick-slide img{
     object-fit: cover;
}

.main-slider .slick-list.draggable{
    height: 100%;
}

.main-slider__slide img{
    height: 100% !important;
}