.footer{
    border-top: 4px solid #DAE0E6;
    padding: 33px 0 0;
    &-title{
        font-family: 'Gotham Pro Narrow';
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #FB8300;
        padding-bottom: 18px;
    }
    &-nav{
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li{
                flex: 0 0 50%;
            }
        }
    }

    ul>li>a{
        display: inline-block;
        font-family: 'Gotham Pro';
        font-size: 15px;
        line-height: 22px;
        color: #333333;
        padding: 5px 0;
        transition: .2s;
        &:hover{
            color: $color-orange;
        }
    }



    &-bottom{
        margin-top: 33px;
        border-top: 1px solid #DAE0E6;
        padding: 36px 0 30px;
    }
    &-contacts{
        font-family: 'Gotham Pro';
        &__tel{
            display: inline-block;
            margin-bottom: 24px;
            position: relative;
            span{
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #333333;
            }
            &:before{
                content: '';
                height: 10px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 1px;
                z-index: -1;
                background-color: #E5E5E5;
                border-radius: 5px;
                transition: background-color .4s;
            }
            &:hover{
                &:before{
                    background-color: $color-orange;
                }
            }
        }
        &__item{
            display: block;
            font-size: 15px;
            line-height: 22px;
            color: #333333;
            &:not(:last-child){
                padding-bottom: 8px;
            }
            a{
                color: #333333;
                transition: .2s;
                &:hover{
                    color: #9D9D9D;
                }
            }
            &.mail{
                a{
                    border-bottom: 1px solid #333;
                }
            }
        }
    }
    &-main-menu{
    }

    &-policy{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Gotham Pro';
        height: 100%;
        &__info{
            span, a{
                display: block;
                font-size: 15px;
                line-height: 22px;
                color: #A3A3A3;
                margin-bottom: 10px;
            }
            a{
                color: #A3A3A3;
                text-decoration: underline;
                transition: .2s;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        &__made{
            a{
                font-size: 15px;
                line-height: 22px;
                color: #A3A3A3;
                svg{
                    width: 125px;
                    height: 25px;
                }
            }
        }
    }
}
@media (max-width: 1199px){
    .footer{
        padding: 18px 0 0;
    }
}
@media (max-width: 991px){
    .footer{
        &-top{
            display: none;
        }
        &-bottom{
            padding: 0;
            margin: 0;
            border: 0;
        }
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .footer{
        &-col{
            margin-bottom: 20px;
        }
    }
}
@media (max-width: 767px){
    .footer{
        padding: 18px 0 27px;
        &-col{
            &-menu{
                display: none;
            }
            &-contacts{
                margin-bottom: 28px;
            }
            &-policy{
                margin-top: 30px;
                padding-top: 16px;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    left: -20px;
                    right: -20px;
                    top: 0;
                    height: 1px;
                    background-color: #DAE0E6;
                }
            }
        }
        &-theme-icon-button{
            width: auto;
            max-width: 290px;
        }
    }
}