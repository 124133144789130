.wrapper-404{
	padding: 30px 0 0;
	&__error-code{
		font-size: 240px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 25px;
	}
	&__title{
		font-size: 48px;
		line-height: 1;
		font-weight: 700;
		text-align: center;
		margin-bottom: 20px;
	}
	&__desc{
		font-size: 18px;
		line-height: 1.4;
		text-align: center;
	}
	&__to-index{
		width: 230px;
		height: 60px;
		margin: 15px auto 0;
	}
}
@media (max-width: 991px){
	.wrapper-404{
		&__error-code{
			margin-bottom: 15px;
			font-size: 170px;
		}
		&__title{
			margin-bottom: 15px;
			font-size: 28px;
		}
		&__desc{
			font-size: 16px;
		}
		&__to-index{
			margin-top: 10px;
		}
	}
}
@media (max-width: 575px){
	.wrapper-404{
		&__error-code{
			margin-bottom: 10px;
			font-size: 120px;
		}		
	}	
}