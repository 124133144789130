.breadcrumbs{
    padding-bottom: 30px;
    ul{
        display: flex;
		flex-direction: row;
		flex-wrap: wrap;
        li{
            position: relative;
            margin-right: 30px;
            a, span{
                display: block;
                font-family: 'Gotham Pro';
                font-size: 15px;
                line-height: 20px;
                color: #9D9D9D;
            }
            &:not(:last-child){
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background-color: #9D9D9D;
                    left: calc(100% + 10px);
                    top: 50%;
                }
            }
        }
    }
}

.sidebar{
	padding-left: 5px;
	&-menu{
		$this: &;
		margin: 0 0 30px 49px;
		padding: 10px 0 10px 40px;
		border-left: 1px solid #DAE0E6;
		&-item{
			&.current-menu-item{
				position: relative;
				#{$this}-link{
					color: #333;
				}
				&:before{
					content: '';
					width: 6px;
					height: 6px;
					position: absolute;
					left: -16px;
					top: 8px;
					background-color: $color-orange;
					border-radius: 6px;
				}
			}
			&:not(:last-child){
				margin-bottom: 10px;
			}
		}
		&-link{
			color: #979797;
			font-size: 15px;
			line-height: 24px;
			transition: .4s;
			&:hover{
				color: #333;
			}
		}
	}
	&-block{
		max-width: 345px;
		margin: 0 auto;
		padding: 0 30px 35px;
		background-color: $color-orange;
		&-title{
			margin: 24px 0 27px;
			font-family: Gotham Pro Narrow;
			font-size: 30px;
			line-height: 38px;
			font-weight: 700;
			color: #fff;
		}
		&-button{
			width: 100%;
			height: 50px;
			color: #fff;
			font-size: 14px;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.77px;
			font-family: Gotham Pro Narrow;
			background-color: #FB4700;
			border: 0;
			border-radius: 3px;
			cursor: pointer;
			transition: .4s;
			&:hover{
				color: #FB4700;
				background-color: #fff;
			}
		}
		&-img{
			max-width: 100%;
			margin-top: -17px;
		}
	}
}

.contacts{
	&-banner{
		display: block;
		margin: 0 0 30px;
		&__img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
	}
    &-info{
        padding: 55px 0 0;
    }
    &-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 55px;
    }
    &-col{
        flex: 0 0 45%;
        b{
            display: block;
            padding-bottom: 8px;
            font-family: 'Gotham Pro Narrow';
            font-size: 15px;
            line-height: 14px;
            color: #FB8300;            
        }
        a, span{
            font-family: 'Gotham Pro';
            font-size: 16px;
            line-height: 24px;
            color: #333333;
        }
        a{
            color: #333333;
        }
    }
    &-map{
        height: 100%;
    }
}
.contacts-row-spacing{
	margin-top: 50px;
	.contacts-info{
		margin-left: 130px;
	}
}
.js-tab-target{
	display: none;
	&.active{
		display: block;
	}
}
.contact-page{
	margin-bottom: 108px;
}
.contacts-order{
	&-1{
		order: 1;
	}
	&-2{
		order: 2;
	}
}
.contacts-tab{
	&__row{
		display: flex;
		justify-content: space-between;
        padding-bottom: 48px;

	}
	&__container{
		display: flex;
		margin-right: 170px;
	}
	&__item{
		display: flex;
		align-items: center;
		margin-right: 62px;
		cursor: pointer;
		span{
			font-size: 15px;
			line-height: 15px;
			color: #333;
			font-weight: 500;
			padding-bottom: 2px;
			border-bottom: 1px dashed #FB8300;
			transition: color .3s ease;
		}
		&.active{
			span{
				color: #9D9D9D;
				border-color: transparent;
			}
			&:hover{
				
				span{
					border-color: #FB8300;
				}
			}
		}
		&:hover{
			span{
				border-color: transparent;
			}
		}
	}
}
.page{
    padding: 30px 0 120px;
    &-title{
		display: flex;
		align-items: flex-end;
        font-family: 'Gotham Pro Narrow';
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #333333;
		&__name{
			font-family: 'Gotham Pro Narrow';
			font-weight: 700;
			font-size: 36px;
			line-height: 44px;
			color: #333333;
		}
        &__count{
            font-family: 'Gotham Pro Narrow';
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #9D9D9D;
            padding-left: 20px;
        }
    }
}

.page-category{
    padding: 30px 0 90px;
}


.content-text {
	ol ol, ol ul, ul ol, ul ul {
		padding-top: 8px;
	}
	h1,h2,h3,h4,h5,h6{
		clear: both;
	}
	h1 {
		padding: 0 0 18px;
		@include font-h1;
	}
	h2 {
		padding: 15px 0;
		@include font-h2;
	}
	h3 {
		padding: 14px 0 5px;
		@include font-h3;
	}
	p {
		padding: 0 0 18px;
		@include font-p;
	}
	a {
		color: $color-general;
		text-decoration: underline;
		transition: .4s;
		&:hover, &focus {
			color: #fb4700;
			text-decoration: none;
		}
		&:visited{
			color: #863bab;
		}
	}
	ol + h3, ul + h3{
		padding-top: 16px;
	}
	ol {
		padding: 4px 0 0;
		margin-left: 28px;
		> li {
			@include font-p;
			list-style-type: decimal;
			padding-bottom: 8px;
			padding-left: 8px;
		}
		ul{
			li:last-child{
				padding-bottom: 0;
			}
		}
	}
	ul {
		margin-left: 35px;
		padding-top: 4px;
		> li {
			position: relative;
			@include font-p;
			padding-bottom: 8px;
			&:before {
				content: '–';
				position: absolute;
				left: -20px;
			}
		}
	}
	img {
		max-width: 100%;
		width: 100% !important;
		height: auto;
		margin: 14px 0 17px;
		-o-object-fit: cover;
		object-fit: cover;
		border-radius: $radius;
		&.noresize{
			height: auto !important;
			width: auto !important;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
	table {
		border: 0;
		th, td {
			color: #373a3c;
			border: 1px solid #DAE0E6;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			padding: 14px 20px;
			// border-right: 1px solid $color-border;
			// border-bottom: 1px solid $color-border;

		}
		thead {
			tr {
				th {
					color: #999;
					background-color: #f5f5f5;
				}
			}
		}
		tbody {
			tr {
				&:first-child{
					border-bottom: 3px solid $color-orange;
					td{
						font-family: Gotham Pro Narrow;
						color: #BBC1C8;
						// border-bottom-color: $color-orange;
						border-bottom: 0;
						p{
							font-family: Gotham Pro Narrow;
							color: #BBC1C8;
						}
					}
				}
			}
		}
		p{
			padding: 0;
		}
	}
}

.quote{
	border: 1px solid #E6E6E6;
	border-left: 4px solid $color-orange;
	background-color: #fff;
	padding: 25px 30px 25px 26px;
	position: relative;
	overflow: hidden;
	margin: 20px 0 10px;
	&-text{
		color: $color-text;
		font-size: 16px;
		line-height: 24px;
	}
}

.files{
	margin: 45px 0 10px;
}
.file {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 170px;
	padding: 25px;
	margin-bottom: 10px;
	background-color: #F4F4F4;
	transition: .4s;
	&-name{
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		color: $color-text;
		transition: .4s;
	}
	&-icon{
		width: 20px;
		height: 24px;
		margin-right: 10px;
		fill: $color-orange;
		transition: .4s;
	}
	&-info{
		color: $color-text;
		font-size: 14px;
		line-height: 20px;
		transition: .4s;
	}
	$this: &;
	&:hover{
		background-color: $color-orange;
		#{$this}-name{
			color: #fff;
		}
		#{$this}-icon{
			fill: #fff;
		}
		#{$this}-info{
			color: #fff;
		}
	}
}

.gallery-item {
	padding: 10px 0 22px;
	&-thumbnail {
		position: relative;
		display: block;
		width: 100%;
		height: 300px;
		margin: 0 0 10px;
		background-position: center;
		background-size: cover;
		border-radius: $radius;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			background: $whiteIconZoom center/30px 30px no-repeat $color-orange;
			transition: .4s;
		} 
		&:hover:after {
			opacity: 0.8;
		}
	}
	&-sign {
		p {
			display: block;
			@include font-sign;
		}
	}
}

.certificates{
	margin: 40px 0;
}
.certificate {
	margin-bottom: 24px;
	&-thumbnail {
		position: relative;
		display: block;
		width: 300px;
		height: 220px;
		background: top center/cover no-repeat;
		border: 1px solid #ddd;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			background: $whiteIconZoom center/30px 30px no-repeat $color-orange;
			transition: .4s;
		}
		&:before{
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			bottom: -1px;
			left: calc(100% + 1px);
			border-style: solid;
			border-width: 60px 50px 0 0;
			border-color: #f2f2f2 transparent transparent transparent;
		}
		img{
			width: 100% !important;
			height: 100% !important;
			object-fit: contain;
			object-position: center;
		}
		&:hover:after {
			opacity: 0.9;
		}
	}
	&-sign{
		p{
			display: block;
			@include font-sign;
		}
	}
	
}
@media (max-width: 1199px){
	.sidebar{
		padding: 0;
		&-block{
			padding: 0 15px 20px;
			&-title{
				margin: 16px 0 18px;
				font-size: 26px;
				line-height: 32px;
			}
		}
		&-menu{
			padding: 10px 0 10px 20px;
			margin: 0 0 30px 30px;
		}
	}
	.page{
		padding: 25px 0 70px;
		&-title{
			
		}
	}
	.contacts-tab{
		&__row{
			padding-bottom: 28px;

		}
	}
	.breadcrumbs{
		padding-bottom: 15px;
	}
	.contact-page{
		margin-bottom: 60px;
	}
	.contacts-tab{
		&__container{
			margin-right: 0px;
		}
		&__item{
			align-items: end;
		}
	}
	.contacts-row-spacing{
		margin-top: 30px;
		.contacts-info{
			margin-left: 30px;
		}
	}
	.contacts{
		&-row{
			padding-bottom: 12px;
		}
		&-info{
			padding: 0;
		}
	}
	.content{
		&-text{
			h1{
				padding: 0 0 14px;
				font-size: 32px;
				line-height: 38px;
			}
			h2{
				padding: 14px 0;
				font-size: 20px;
				line-height: 24px;
			}
			h3{
				padding: 10px 0 4px;
				font-size: 16px;
				line-height: 18px;
			}
			p{
				padding: 0 0 10px;
				font-size: 15px;
				line-height: 19px;
			}
			img{
				margin: 5px 0;
			}
			ul,ol{
				margin-left: 18px;
				>li{
					padding-left: 6px;
					padding-bottom: 6px;
					font-size: 15px;
					line-height: 19px;
				}
			}
		}
	}
	.quote{
		margin: 10px 0;
		padding: 17px;
		&-text{
			font-size: 15px;
			line-height: 19px;
		}
	}
	.files{
		margin: 25px 0;
	}
	.file{
		height: 125px;
		padding: 15px;
		&-name{
			font-size: 15px;
			line-height: 19px;
		}
	}
	.gallery{
		&-item{
			padding: 10px 0;
			&-thumbnail{
				height: 230px;
				margin: 0 0 8px;
			}
		}
	}
	.certificates{
		margin: 25px 0;
	}
	.certificate{
		&-thumbnail{
			width: calc(100% - 30px);
			height: 180px;
			&:before{
				border-width: 40px 30px 0 0;
			}
		}
	}
}
@media (max-width: 991px){
	.sidebar{
		&-menu{
			margin: 20px 0;
		}
	}
	.contacts-tab{
		&__row{
			flex-direction: column;
		}
		&__container{
			margin-top: 20px;
		}
		&__item{
			margin-right: 30px;
		}
	}
}
@media (max-width: 767px){
	.contacts{
		&-map{
			margin-top: 20px;
			height: 350px;
		}
	}
	.contacts-row-spacing{
		.contacts-info{
			margin-left: 0px;
			margin-top: 30px;
		}
	}
	
	.contacts-order{
		&-1{
			order: 2;
		}
		&-2{
			order: 1;
		}
	}
	.certificate{
		&-thumbnail{
			height: 165px;
		}
	}
	.contacts-tab{
		&__container{
			flex-direction: column;
		}
		&__item{
			margin-bottom: 15px;
			margin-right: 0px;
		}
	}
}

.sidebar-review-buttonlink{
	color: white !important;
	margin-bottom: 10px !important;
	min-height: 50px;
	height: auto !important;
	padding: 10px 10px;
	flex-direction: column;

	@media(max-width: 1199px){
		&{
			font-size: 13px;
		}
	}

	&-adress{
		padding-top: 5px;
		font-size: 11px;
	}

	&:last-child{
		margin-bottom: 0 !important;
	}

	&:hover{
		color: #FB4700 !important;
	}
}
.seo-with-img{
	&__container{
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 360px;
		@media (max-width:1400px){
			height: 320px;
		}
		@media (max-width:992px){
			height: 300px;
		}
		@media (max-width:700px){
			height: auto;
			flex-direction: column;
		}
	}
	&__img{
		width: 40%;
		height: 100%;
		@media (max-width:700px){
			margin-bottom: 20px;
			width: 100%;
			height: 300px;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__content-wrapper{
		height: 100%;
		overflow: hidden;
		width: 55%;
		@media (max-width:700px){
			width: 100%;
			height: 300px;
		}
	}
	&__content{
		overflow: auto;
		height: 100%;
		padding-right: 20px;

		scrollbar-width: thin;          /* "auto" или "thin" */
		scrollbar-color: #eef1f4 orange;   /* цвет бегунка и зоны отслеживания */
		&::-webkit-scrollbar {
			width: 4px;               /* ширина всей полосы прокрутки */
		}
		&::-webkit-scrollbar-track {
			background: #eef1f4;        /* цвет зоны отслеживания */
		}
		&::-webkit-scrollbar-thumb {    /* цвет бегунка */
			border-radius: 20px;       /* округлось бегунка */
			border: 3px solid orange;  /* отступ вокруг бегунка */
		}
	}
}