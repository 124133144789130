.review{
	display: flex;
	position: relative;
	color: #333;
	&:not(:last-child){
		margin-bottom: 75px;
	}
	&-quotes{
		width: 64px;
		height: 45px;
		position: absolute;
		top: 9px;
		right: 0;
		fill: #DFE4EA;
	}
	&-info{
		width: 100%;
	}
	&-logo{
		flex: 0 0 200px;
		margin-right: 40px;
		padding: 0 8px;
		&__img{
			width: 100%;
			object-position: center top;
			object-fit: scale-down;
		}
		&__text{
			color: #DAE0E6;
			font-size: 50px;
			line-height: 1;
			text-align: center;
			font-weight: 900;
		}
	}
	&-company{
		margin-bottom: 4px;
		font-size: 22px;
		line-height: 28px;
		font-weight: 700;
		font-family: Gotham Pro Narrow;
		&-type{
			font-size: 14px;
			line-height: 20px;
			color: #737779;
		}
	}
	&-text{
		margin: 25px 0 20px;
		font-size: 16px;
		line-height: 26px;
	}
	&-fio{
		vertical-align: middle;
		font-size: 16px;
		line-height: 20px;
	}
	&-dolzhnost{
		vertical-align: middle;
		font-size: 14px;
		line-height: 20px;
		color: $color-orange;
	}
	&-bottom{
		padding: 0 0 22px;
		border-bottom: 1px solid #ECECEC;
	}
	&-file{
		display: inline-block;
		padding-top: 21px;
		&__icon{
			width: 20px;
			height: 24px;
			margin-right: 12px;
			fill: #BBC1C8;
		}
		&__text{
			padding-bottom: 3px;
			font-size: 14px;
			line-height: 20px;
			color: #737779;
			border-bottom: 1px dashed #737779;
			transition: color .4s, border-color .4s;
		}
		$this: &;
		&:hover{
			#{$this}__text{
				color: $color-orange;
				border-color: $color-orange;
			}
		}
	}
}
.clients-logos{
	display: flex;
	flex-wrap: wrap;
	padding: 52px 0 0;
	margin-top: 90px;
	border-top: 1px solid #DAE0E6;
}
.client-logo{
	height: 118px;
	max-width: 20%;
	flex: 0 0 20%;
	padding: 0 15px;
	margin-bottom: 50px;
	object-fit: scale-down;
	object-position: center;
}
@media (max-width: 1199px){
	.review{
		margin-bottom: 30px;
		&-company{
			&-type{

			}
		}
		&-text{
			margin: 15px 0 10px;
			font-size: 15px;
			line-height: 22px;
		}
		&-file{
			padding-top: 12px;
		}
		&-bottom{
			padding: 0 0 15px;
		}
		&-logo{
			&__text{
				font-size: 46px;
			}
		}
		&:not(:last-child){
			margin-bottom: 40px;
		}
		&-quotes{
			top: 0;
			transform-origin: right top;
			transform: scale(.8);
		}
	}
	.clients-logos{
		margin-top: 40px;
		padding: 25px 0 0;
	}
	.client-logo{
		height: 75px;
		padding: 0 8px;
		margin-bottom: 25px;
	}
}
@media (max-width: 991px){
	.review{
		&-logo{
			flex: 0 0 140px;
			padding: 0;
			margin-right: 25px;
		}
	}
}
@media (max-width: 767px){
	.client-logo{
		max-width: 33.3%;
		flex: 0 0 33.3%;
	}
	.review{
		&-dolzhnost{
			display: block;
		}
	}
}
@media (max-width: 575px){
	.review{
		flex-direction: column;
		&-logo{
			flex: 0 0 auto;
			margin: 0 0 15px;
			&__img{
				display: block;
				width: auto;
				max-width: 100%;
				margin: 0 auto;
			}
		}
		
		&-quotes{
			display: none;
		}
	}
	.client-logo{
		max-width: 50%;
		flex: 0 0 50%;
	}
}