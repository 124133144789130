.header{
    $this: &;
    height: 138px;
    &-top{
        border-bottom: 1px solid #D8D8D8;
        &__wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &__phone{
            padding-right: 30px;
            border-right: 1px solid #D8D8D8;
            margin-right: 20px;
            a{
                svg{
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                    margin-top: -2px;
                    fill: #737779;
                }
                span{
                    font-family: 'Gotham Pro Narrow';
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #333333;
                    transition: .2s;
                    &:hover{
                        color: #9D9D9D;
                    }
                }
            }
        }
        &__nav{
            flex: 1 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    &-main{
        z-index: 10;
        border-bottom: 1px solid #D8D8D8;
        background-color: #fff;
        &__wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            position: relative;
        }
        &--fixed{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            z-index: 999;
        }
    }
    &-nav{
        font-family: 'Gotham Pro';
        > ul{
            display: flex;
            flex-direction: row;
            > li{
                position: relative;
                > a{
                    display: inline-block;
                    padding: 13px 16px;
                    font-size: 15px;
                    line-height: 20px;
                    color: #333333;
                    transition: .2s;
                }
                > ul{
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    padding: 18px 30px;
                    width: 410px;
                    background-color: #fff;
                    border: 1px solid #E6E6E6;
                    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.116313);
                    z-index: 11;

                    > li{
                        > a{
                            display: inline-block;
                            padding: 8px 0;
                            font-size: 15px;
                            line-height: 22px;
                            color: #333333;
                            transition: .2s;
                            &:hover{
                                color: $color-orange;
                            }
                        }
                    }
                }
                &:hover{
                    > a{
                        color: #9D9D9D;
                    }
                    > ul{
                        display: block;
                    }
                }
            }
        }
    }
    &-contacts{
        &__mail{
            display: inline-block;
            border-bottom: 1px solid #D8D8D8;
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 20px;
            text-align: right;
            color: #333333;
            transition: .2s;
            &:hover{
                color: #9D9D9D;
            }
        }
        &__modal{
            font-family: 'Gotham Pro Narrow';
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            color: $color-orange;
            margin-left: 30px;
            transition: .2s;
            &:hover{
                color: #FB4700;
            }
        }
    }
    &-logo{
        width: 180px;
        height: 50px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    &-catalog{
        padding-left: 60px !important;
        padding-right: 35px !important;
    }
    &-search{
        position: relative;
        flex: 1 0 auto;
        height: 70px;
        border-left: 1px solid #D8D8D8;
        border-right: 1px solid #D8D8D8;
        form{
            height: 100%;
            position: relative;
            input{
                height: 100%;
                width: 100%;
                border: none;
                padding: 0 55px;
                font-family: 'Gotham Pro';
                font-size: 16px;
                line-height: 22px;
                color: #737779;
            }
            button{
                position: absolute;
                width: 18px;
                height: 18px;
                background-color: transparent;
                border: none;
                background-image: url('../images/search.svg');
                background-repeat: no-repeat;
                background-position: center;
                left: 20px;
                top: calc(50% - 9px);
            }
        }
        &__drop{
            display: none;
            position: absolute;
            top: calc(100% + 10px);
            left: -1px;
            z-index: 999;
            width: calc(100% + 2px);
            background-color: #fff;
            border: 1px solid #D8D8D8;
            &-wrapper{
                padding: 30px 15px 5px;
            }
            &-title{
                padding-bottom: 10px;
                border-bottom: 1px solid #D8D8D8;
                margin-bottom: 10px;
            }
            &-list{
                padding-bottom: 25px;
                li{
                    a{
                        display: inline-block;
                        width: 100%;
                        padding: 5px 0;
                        font-size: 15px;
                        line-height: 20px;
                        color: #333333;
                        transition: .2s;
                        &:hover{
                            color: #FB8300;
                        }
                    }
                }
            }
        }
    }
    &-buttons{
        $this: &;
        display: flex;
        flex-direction: row;
        &__button{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 70px;
            padding: 0 20px;
            &:last-child{
                padding-right: 0;
            }
            &:hover{
                #{$this}__title{
                    color: $color-orange;
                }
            }
        }
        &__icon{
            fill: $color-orange;
            margin-top: -4px;
            &.cart{
                width: 26px;
                height: 26px;
            }
            &.favorite{
                width: 24px;
                height: 20px;
                fill: transparent;
                stroke: $color-orange;
            }
            &.catalog{
                width: 19px;
                height: 16px;
                position: relative;
                span{
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: $color-orange;
                    border-radius: 1px;
                    left: 0;
                    &:nth-child(1){
                        top: 0;
                    }
                    &:nth-child(2){
                        top: calc(50% - 1px)
                    }
                    &:nth-child(3){
                        bottom: 0;
                    }
                }
            }
        }
        &__title{
            font-family: 'Gotham Pro Narrow', sans-serif;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            margin-left: 15px;
            margin-right: 10px;
            transition: .2s;
            &.cart{
                font-weight: 700;
                &:empty{
                    &:before{
                        content: 'Корзина';
                        display: inline;
                    }
                }
            }
        }
        &__counter{
            background-color: #EEF1F4;
            border-radius: 3px;
            font-family: 'Gotham Pro';
            font-weight: 700;
            font-size: 15px;
            line-height: 14px;
            text-align: center;
            color: #737779;
            width: 30px;
            height: 30px;
            margin-top: -5px;
            transition: .2s;
            transform: scale(1);
            &:empty{
                transform: scale(0);
            }
        }
    }
    &-drop{
        $this: &;
        display: flex;
        flex-direction: row;
        top: 100%;
        left: 0;
        width: 100%;
        position: absolute;
        background-color: #fff;
        border: 1px solid #E6E6E6;
        padding: 10px 25px;
        z-index: 10;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.116313);
        &__main-menu{
            flex: 0 0 300px;
            padding: 15px 0 15px 25px;
            border-right: 1px solid #D8D8D8;
            > ul{
                > li{
                    > a{
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 22px;
                        color: #333;
                        display: block;
                        position: relative;
                        padding: 10px 0;
                        transition: .4s;
                        &:before{
                            content: '';
                            width: 21px;
                            height: 4px;
                            position: absolute;
                            top: 18px;
                            left: -30px;
                            background-color: $color-orange;
                            opacity: 0;
                            border-radius: 3px;
                            transition: .4s;
                        }
                        >span{
                            display: block;
                            transition: .4s;
                        }
                    }
                    &:hover{
                        #{$this}__sub-menu{
                            visibility: visible;
                            z-index: 1;
                        }
                        >a{
                            >span{
                                transform: translateX(15px);
                            }
                            &:before{
                                transform: translateX(15px);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &__sub-menu{
            display: flex;
            visibility: hidden;
            left: 22.75%;
            right: 0;
            top: 10px;
            bottom: 10px;
            position: absolute;
            margin: 15px 0;
            padding: 17px 0 0 50px;
            background-color: #fff;
            > ul{
                display: flex;
                flex-wrap: wrap;
                flex: 1 0 auto;
                flex-direction: column;
                > li{
                    width: 49%;
                    > a{
                        display: inline-block;
                        padding: 10px;
                        padding-right: 25px;
                        font-size: 15px;
                        line-height: 22px;
                        color: #333;
                        transition: .4s;
                        &:hover{
                            color: $color-orange;
                        }
                    }
                }
            }
        }
        &__product{
            flex: 0 0 380px;
            padding: 10px 0 10px 28px;
            border-left: 1px solid #D8D8D8;
            &-title{
                margin-bottom: 15px;
                font-family: 'Gotham Pro Narrow';
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                color: #333333;
            }

            .product-card{
                transform-origin: left top;
                transform: scale(.9);
                border: 1px solid #D8D8D8;
                &__favorite-add,&__actions{
                    opacity: 1;
                }
            }
        }
        &:after{
            content: '';
            position: absolute;
            left: -1px;
            right: -1px;
            height: 12px;
            top: -11px;
            z-index: 2;
        }
    }

    &-mobile{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        height: 50px;
        display: none;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #DDDDDD;
        &__menu{
            border-right: 1px solid #DDDDDD;
            &-wrapper{
                width: 18px;
                height: 14px;
                position: relative;
                span{
                    position: absolute;
                    background-color: #737779;
                    border-radius: 1px;
                    width: 100%;
                    height: 2px;
                    transition: .2s;
                    &:nth-child(1){
                        top: 0;
                    }
                    &:nth-child(2){
                        top: calc(50% - 1px);
                    }
                    &:nth-child(3){
                        bottom: 0;
                    }
                }
                &::after{
                    content: '';
                    display: block;
                    width: 5px;
                    height: 5px;
                    background-color: #FB8300;
                    border-radius: 3px;
                    top: -7px;
                    left: -7px;
                    position: absolute;
                    transform: scale(0);
                    transition: .2s;
                }
                &.flag{
                    &::after{
                        transform: scale(1);
                    }
                }
            }
            &.opened{
                span{
                    &:nth-child(1){
                        transform: rotate(45deg);
                        top: 6px;
                    }
                    &:nth-child(2){
                        width: 0;
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg);
                        bottom: 6px;
                    }
                }
            }
        }
        &__logo{
            display: block;
            width: 105px;
            height: 30px;
            img{
                height: 100%;
                width: auto;
                object-fit: contain;
            }
        }
        &__buttons{
            display: flex;
            flex-direction: row;
            div{
                width: 50px;
                height: 50px;
            }
        }
        &__call{
            border-left: 1px solid #DDDDDD;
            svg{
                width: 16px;
                height: 16px;
                fill: #FB4700;
            }
        }
        &__cart{
            position: relative;
            border-left: 1px solid #DDDDDD;
            svg{
                width: 18px;
                height: 18px;
                fill: $color-orange;
            }
            span{
                display: block;
                position: absolute;
                font-family: 'Gotham Pro';
                font-weight: 700;
                font-size: 8px;
                line-height: 8px;
                text-align: center;
                color: #FFFFFF;
                background-color: #FB4700;
                border-radius: 3px;
                padding: 3px 3px;
                right: 5px;
                top: 12px;
            }
        }
        &__cell{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            flex: 0 0 50px;
            height: 50px;
        }
    }
}

.hit-area{
    position: absolute;
    top: 0;
    left: -274px;
    width: calc(100% + 274px);
    height: 100%;
    z-index: 1;
    pointer-events: none;
    fill: transparent;
    &__anchor{
        width: 274px;
        height: 41px;
    }
    &__menu{
        height: 100%;
        width: calc(100% - 274px);
        x: 274px;
    }
    &__path{
        pointer-events: auto;
    }
}

.mobile{
    &-menu{
        position: fixed;
        top: 50px;
        left: 100%;
        width: 100vw;
        height: calc(100vh - 50px);
        background-color: #fff;
        z-index: 99;
        overflow-y: scroll;
        transition: 400ms cubic-bezier(0.7, 0, 0.3, 1) 0s;
        &.opened{
            left: 0%;
        }
        &.no-scroll{
            overflow: hidden;
        }
        .sub-menu{
            position: fixed;
            width: 100vw;
            height: calc(100vh - 50px);
            padding-bottom: 14px;
            top: 50px;
            left: 100%;
            z-index: 9;
            background-color: #fff;
            overflow: auto;
            transition: left .4s;
            &.opened{
                left: 0;
            }
            a{
                justify-content: space-between;
            }
            &-back{
                height: 50px;
                padding: 14px 20px 15px;
                border-bottom: 1px solid #ddd;
                color: #737779;
                font-size: 15px;
                line-height: 22px;
                background-color: #EEF1F4;
                svg{
                    width: 6px;
                    height: 10px;
                    transform: rotate(-180deg);
                    margin: 0 7px 3px 0;
                    fill: #9D9D9D;
                }
            }
            li{
                padding: 14px 20px 0;
            }
            &-link{
                font-size: 15px;
                line-height: 22px;
                color: #333333;
            }
        }
    }
    &-row-button{
        $this: &;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 12px 15px;
        border-bottom: 1px solid #DDDDDD;
        &__icon{
            width: 18px;
            height: 14px;
            position: relative;
            margin-right: 12px;
            svg{
                width: 100%;
                height: 100%;
                fill: $color-orange;
                margin-top: -14px;
            }
        }
        &__text{
            font-family: 'Gotham Pro Narrow';
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #333333;
        }
        &__next{
            position: absolute;
            right: 15px;
            svg{
                width: 6px;
                height: 10px;
                fill: #333;
            }
            &-counter{
                background-color: #EEF1F4;
                border-radius: 3px;
                width: 24px;
                height: 24px;
                font-family: 'Gotham Pro';
                font-weight: 700;
                font-size: 12px;
                line-height: 11px;
                text-align: center;
                color: #737779;
                padding: 5px 7px;
            }
        }

        &.catalog{
            background-color: $color-orange;
            border-bottom: none;
            #{$this}__icon{
                span{
                    position: absolute;
                    background-color: #fff;
                    border-radius: 1px;
                    width: 100%;
                    height: 2px;
                    transition: .2s;
                    &:nth-child(1){
                        top: 0;
                    }
                    &:nth-child(2){
                        top: calc(50% - 1px);
                    }
                    &:nth-child(3){
                        bottom: 0;
                    }
                }
            }
            #{$this}__text{
                color: #FFFFFF;
            }
            #{$this}__next{
                svg{
                    fill: #fff;
                }
            }
            ul li a{
                svg{
                    width: 6px;
                    height: 10px;
                    flex: 0 0 auto;
                    margin-left: 10px;
                    fill: $color-orange;
                }
            }
        }

        &.search{
            #{$this}__icon{
                width: 16px;
                height: 16px;
                svg{
                    fill: #737779;
                }
            }
            #{$this}__text{
                font-family: 'Gotham Pro';
                font-size: 15px;
                line-height: 22px;
                color: #737779;
                font-weight: 400;
            }
        }
    }
    &-nav{
        padding: 15px 20px;
        ul{
            li{
                a{
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding: 5px 0;
                    span{
                        font-size: 15px;
                        line-height: 22px;
                        color: #333333;
                    }
                    svg{
                        width: 6px;
                        height: 10px;
                        flex: 0 0 auto;
                        margin-left: 10px;
                        fill: $color-orange;
                    }
                }
            }
        }
    }
    &-contacts{
        padding: 25px 15px 15px;
        border-top: 1px solid #DDDDDD;
        &__tel{
            display: inline-flex;
            position: relative;
            margin-bottom: 24px;
            span{
                font-family: 'Gotham Pro';
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #333333;
                position: relative;
                z-index: 1;
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                background-color: #E5E5E5;
                border-radius: 5px;
                width: 100%;
                height: 10px;
                left: 0;
                bottom: 2px;
            }
        }
        &__item{
            display: block;
            font-family: 'Gotham Pro';
            font-size: 15px;
            line-height: 22px;
            color: #333333;
            padding-bottom: 10px;
            a{
                color: #333333;
            }
        }
        
        .theme-icon-button{
            width: 100%;
            margin-top: 15px;
        }
    }
    &-search{
        &-form{
            display: flex;
            height: 50px;
            button{
                border: none;
                outline: none;
                background-color: $color-orange;
                width: 100px;
                color: #fff;
                font-size: 14px;
                line-height: 1;
                font-weight: 700;
                letter-spacing: 0.77px;
                text-transform: uppercase;
                font-family: Gotham Pro Narrow;
                height: 100%;
            }
            input{
                flex: 1 0 auto;
                padding: 0 12px;
                border: 0;
                border-bottom: 1px solid #ddd;
            }
        }
    }
}

@media(max-width: 1199px){
    .header{
        height: auto;
        &-top, &-main{
            display: none;
        }
        &-mobile{
            display: flex;
        }
    }
}

@media(max-width:575px){
    .header-search__drop-wrapper{
        padding: 20px 15px 5px;
    }
    .header-search__drop-title{
        padding-bottom: 8px;
        margin-bottom: 8px;
    }
}